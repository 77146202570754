import cookie from 'js-cookie';
import config, { isDev } from 'config';

class CookieItem {
  private NAME: string;

  constructor(name: string) {
    this.NAME = name;
  }

  public get = (): string | null => cookie.get(this.NAME) || null;

  public set = (token: string | null) => {
    const { domain } = config;

    if (!token) {
      return cookie.remove(this.NAME, { domain });
    }

    return cookie.set(this.NAME, token, { domain });
  };
}

const accessTokenName = isDev ? 'stage_token' : 'token';
const refreshTokenName = isDev ? 'stage_refresh' : 'refresh';

const tokens = {
  access: new CookieItem(accessTokenName),
  refresh: new CookieItem(refreshTokenName),
};

export default tokens;
