/* eslint-disable no-nested-ternary */
import React, { useMemo, memo } from 'react';
import { NavLink } from 'react-router-dom';

import { Tooltip } from '@material-ui/core';

import { ChildrenType } from 'utils/types';
import { RoleChecker } from 'utils/HOC/protector';
import { useTranslation } from 'react-i18next';

type PropType = {
  to?: string;
  onlyForTablets?: boolean;
  isTablet?: boolean;
  href?: string;
  roles?: string[];
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  icon?: ChildrenType;
  title: string;
  hasNotification?: boolean;
  notificationCount?: number;
};

const LinkItem: React.FC<PropType> = (props) => {
  const { t } = useTranslation();

  const { Component, componentProps } = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let Component;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let componentProps;

    if (props.to) {
      Component = NavLink;
      componentProps = {
        to: props.to,
        activeClassName: props.hasNotification
          ? 'current-link nav-item-has-notification'
          : 'current-link',
        exact: true,
      };
    } else {
      Component = 'a';
      componentProps = {
        to: props.to || '',
        href: props.href,
        target: '_blank',
        rel: 'noopener noreferrer',
      };
    }
    return {
      Component,
      componentProps,
    };
  }, [props.to, props.hasNotification, props.href]);

  if (props.onlyForTablets && !props.isTablet) {
    return null;
  }

  const subMenuItemTitles = [t('recruiters reports'), t('weekly reports')];

  const isSubMenuItem = subMenuItemTitles.includes(props.title);

  return (
    <RoleChecker roles={props.roles || 'any'}>
      <Tooltip title={props.title}>
        <Component
          {...componentProps}
          onClick={props.onClick}
          className={
            props.hasNotification
              ? 'nav-item-has-notification'
              : isSubMenuItem
              ? 'sub-item'
              : ''
          }
        >
          <span
            className={props.hasNotification ? 'nav-item-has-notification' : ''}
          >
            {props.icon}
          </span>

          <span className="nav-item-title">{props.title}</span>
          {props.notificationCount && (
            <span className={props.notificationCount ? 'nav-item-count' : ''}>
              +{props?.notificationCount}
            </span>
          )}
        </Component>
      </Tooltip>
    </RoleChecker>
  );
};

export default memo(LinkItem);
