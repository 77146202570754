/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck

import React, { useMemo, useCallback, memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import closeImage from 'ui/images/sidebar-close.svg';
import openImage from 'ui/images/sidebar-open.svg';

import { isAdminOrHRDirector } from 'utils/helpers';
import { routePaths } from 'utils/constants';
import { UserRoleEnum } from 'utils/types.d';
import { useAppDispatch, useAppSelector } from 'utils/hooks';

import { toggleSidebar } from 'store/main';

// temp disabled
// import SubLinkItem from './SubLinkItem';
import VacancyIcon from 'ui/components/VacancyIcon';
import UserIcon from 'ui/components/UserIcon';
import TeamUsersIcon from 'ui/components/TeamUsersIcon';
import StyledMain from './NavMenu.styles';
import LinkItem from './LinkItem';

type NavMenuPropsType = {
  isSidebarOpen?: boolean;
  isOpen: boolean;
};

const NavMenu: React.FC<NavMenuPropsType> = (props) => {
  const { isOpen } = props;
  const { t } = useTranslation();
  const { isSidebarOpen, user } = useAppSelector(({ main }) => main);
  const { notificationsList } = useAppSelector(
    ({ notificationsList }) => notificationsList,
  );

  const dispatch = useAppDispatch();

  const openSidebar = (): void => {
    dispatch(toggleSidebar(true));
  };

  const closeSidebar = (): void => {
    dispatch(toggleSidebar(false));
  };

  // const [notificationsObj, setnotificationsObj] = useState();

  // useEffect(() => {
  //   const obj = notificationsList
  //     .filter((n) => !n.isRead)
  //     .reduce((acc, notification) => {
  //       const key = notification.iconType;
  //       // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //       !acc[key] ? (acc[key] = [notification]) : acc[key].push(notification);

  //       return acc;
  //     }, {});

  //   setnotificationsObj(obj);

  //   console.log(notificationsList, 'notificationsList');
  //   console.log(obj, 'obk');
  // }, [notificationsList]);

  const notificationsObj = useMemo(() => notificationsList
      .filter((n) => !n.isRead)
      .reduce((acc, notification) => {
        const key = notification.iconType;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !acc[key] ? (acc[key] = [notification]) : acc[key].push(notification);

        return acc;
      }, {}), [notificationsList]);

  const getCandidateCount = useCallback(() => {
    const candidateCount = notificationsObj?.candidate?.length;
    const commentCount = notificationsObj?.comment?.length;

    if (!candidateCount && !commentCount) return null;

    if (candidateCount && !commentCount) {
      return candidateCount;
    }

    if (!candidateCount && commentCount) {
      return commentCount;
    }

    return candidateCount + commentCount;
  }, [notificationsObj?.candidate?.length, notificationsObj?.comment?.length]);

  const candidateNotificationsCount = useMemo(
    () => getCandidateCount(),
    [getCandidateCount],
  );

  if (!user) return null;

  const isAdministrator = isAdminOrHRDirector(user.role);

  // temp hide
  // const reportTitle = isAdministrator ? 'reports' : 'my reports';
  const teamsTitle = isAdministrator ? 'teams' : 'my teams';

  const navList = [
    {
      to: routePaths.vacancy.list,
      title: t('vacancies'),
      // icon: <BusinessCenterIcon />,
      icon: <VacancyIcon />,
      hasNotification: Boolean(notificationsObj?.vacancy?.length),
      notificationCount: notificationsObj?.vacancy?.length,
    },
    {
      to: routePaths.candidate.list,
      title: t('candidates'),
      // icon: <PermIdentityIcon />,
      icon: <UserIcon />,
      roles: [
        UserRoleEnum.Admin,
        UserRoleEnum.Manager,
        UserRoleEnum.Recruiter,
        UserRoleEnum.HRD,
        UserRoleEnum.Client,
      ],
      hasNotification:
        Boolean(notificationsObj?.candidate?.length) ||
        Boolean(notificationsObj?.comment?.length),
      notificationCount:
        candidateNotificationsCount && candidateNotificationsCount,
    },
    // temp hide
    // {
    //   to: routePaths.reports.weekly,
    //   title: t(reportTitle),
    //   icon: <AssignmentIcon />,
    //   roles: [
    //     UserRoleEnum.Admin,
    //     UserRoleEnum.Manager,
    //     UserRoleEnum.Recruiter,
    //     UserRoleEnum.HRD,
    //   ],
    //   sublinks: [
    //     {
    //       to: routePaths.reports.recruiters,
    //       title: t('recruiters reports'),
    //       icon: <AssignmentIcon />,
    //     },
    //     {
    //       to: routePaths.reports.weekly,
    //       title: t('weekly reports'),
    //       icon: <AssignmentIcon />,
    //     },
    //   ],
    // },
    {
      to: routePaths.company.list,
      title: t('clients'),
      // icon: <PermIdentityIcon />,
      icon: <UserIcon />,
      roles: [
        UserRoleEnum.Admin,
        UserRoleEnum.Manager,
        UserRoleEnum.HRD,
        UserRoleEnum.Agent,
      ],
      hasNotification: Boolean(notificationsObj?.client?.length),
      notificationCount: notificationsObj?.client?.length,
    },
    {
      to: routePaths.manager.list,
      title: t('managers'),
      // icon: <PermIdentityIcon />,
      icon: <UserIcon />,
      roles: [UserRoleEnum.Admin, UserRoleEnum.HRD],
      // hasNotification: Boolean(notificationsObj.managers.length),
      // notificationCount: notificationsObj.manangers.length,
    },
    {
      to: routePaths.recruiter.list,
      title: t('recruiters'),
      // icon: <PermIdentityIcon />,
      icon: <UserIcon />,
      roles: [UserRoleEnum.Admin, UserRoleEnum.Manager, UserRoleEnum.HRD],
      // hasNotification: Boolean(notificationsObj.recruiter.length),
      // notificationCount: notificationsObj.recruiter.length,
    },
    // temp hide
    // {
    //   to: routePaths.agents,
    //   title: t('agents'),
    //   icon: <PermIdentityIcon />,
    //   roles: [UserRoleEnum.Admin],
    // },
    {
      to: routePaths.hrDirector.list,
      title: t('hr directors'),
      // icon: <PermIdentityIcon />,
      icon: <UserIcon />,
      roles: [UserRoleEnum.Admin],
      // hasNotification: Boolean(notificationsObj.hrDirector.length),
      // notificationCount: notificationsObj.hrDirector.length,
    },
    {
      to: routePaths.team.list,
      title: t(teamsTitle),
      // icon: <PeopleAltIcon />,
      icon: <TeamUsersIcon />,
      roles: [
        UserRoleEnum.Admin,
        UserRoleEnum.Manager,
        UserRoleEnum.Recruiter,
        UserRoleEnum.HRD,
      ],
      // temp hide
      // hasNotification: Boolean(notificationsObj.team.length),
      // notificationCount: notificationsObj.team.length,
    },
    // temp hide
    // {
    //   to: routePaths.analytics,
    //   title: t('analytics'),
    //   icon: <BarChartIcon />,
    //   icon: analyticsIconPath,
    //   roles: [UserRoleEnum.Admin, UserRoleEnum.Manager, UserRoleEnum.HRD],
    // },
    // {
    //   to: routePaths.finance,
    //   title: t('finance'),
    //   icon: <AccountBalanceWalletIcon />,
    //   icon: financeIconPath,
    //   roles: [UserRoleEnum.Admin],
    // },
    // {
    //   to: routePaths.chat,
    //   title: t('chat'),
    //   icon: <ChatIcon />,
    //   roles: [UserRoleEnum.Client, UserRoleEnum.Manager],
    // },
  ];

  // if (user.role === UserRoleEnum.Recruiter) {
  //   delete navList[2].sublinks;
  // }

  // @ts-ignore
  if (user.role === UserRoleEnum.Client && user?.ownerOfCompany?.isMultiUser) {
    navList.push({
      to: routePaths.staff.list,
      title: t('staff'),
      icon: <UserIcon />,
      roles: [UserRoleEnum.Client],
    });
  }

  return (
    <StyledMain isSidebarOpen={isSidebarOpen}>
      <div className="sidebar-show-icon">
        <img
          src={isOpen ? closeImage : openImage}
          onClick={isOpen ? closeSidebar : openSidebar}
          alt={isOpen ? 'close-sidebar-icon' : 'open-sidebar-icon'}
        />
      </div>
      <nav>
        {navList.map(
          (item) => (
            // !item.sublinks ? (
            <LinkItem key={item.to} isTablet={false} {...item} />
          ),
          // ) : (
          // <SubLinkItem key={item.to} {...item} />
          // ),
        )}
      </nav>
    </StyledMain>
  );
};

export default memo(NavMenu);
