import { createSlice } from '@reduxjs/toolkit';

import { ManagerType } from 'utils/types';

const getInitialStore = () => ({
  managersList: [] as ManagerType[],
  manager: null as unknown as ManagerType,
  count: 0,
  sortBy: 'createdAt',
  sortDirection: 'reverse',
  sortSelect: { value: ['createdAt', 'reverse'], label: 'latest' },
  paginationPerPage: 20,
  paginationPage: 1,
  hasMore: true,
  filter: {
    search: '',
    status: [{ label: 'active', value: 'active' }],
  },
});

const initialStore = getInitialStore();

export type StoreType = typeof initialStore;

export const sliceName = 'managersList';

const managersListSlice = createSlice({
  name: sliceName,
  initialState: getInitialStore(),
  reducers: {
    setManagersList: (store, { payload }) => ({
      ...store,
      managersList: [...store.managersList, ...payload.managers],
      count: payload.count,
    }),
    addManagerToList: (
      store,
      { payload }: { payload: { manager: ManagerType; count: number } },
    ) => ({
      ...store,
      managersList: [payload.manager, ...store.managersList],
      count: payload.count,
    }),
    setManager: (store, { payload }) => ({
      ...store,
      manager: payload,
    }),
    updateOneManager: (
      store,
      { payload }: { payload: ManagerType | null },
    ) => ({
      ...store,
      managersList: store.managersList!.map((manager) =>
        manager!.id === payload?.id ? { ...manager, ...payload } : manager,
      ),
      manager:
        store.manager !== null && store.manager?.id === payload?.id
          ? { ...store.manager, ...payload }
          : store.manager,
    }),
    updateSearch: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        search: payload,
      },
    }),
    updateSort: (store, { payload: { value, label } }) => ({
      ...store,
      sortBy: value[0],
      sortDirection: value[1],
      sortSelect: {
        value,
        label,
      },
    }),
    updateFilter: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        [payload.name]: payload.value,
      },
    }),
    updatePage: (store) => ({
      ...store,
      paginationPage: store.paginationPage + 1,
    }),
    resetPage: (store) => ({
      ...store,
      paginationPage: 1,
    }),
    clearFilter: (store) => ({
      ...store,
      filter: getInitialStore().filter,
    }),
    resetManagersList: (store) => ({
      ...store,
      managersList: [],
      count: 0,
    }),
    isHasMore: (store, { payload }) => ({
      ...store,
      hasMore: payload >= store.paginationPerPage,
    }),
  },
});

export const {
  setManagersList,
  setManager,
  resetManagersList,
  updateSearch,
  updateSort,
  updateFilter,
  clearFilter,
  updatePage,
  resetPage,
  isHasMore,
  updateOneManager,
  addManagerToList,
} = managersListSlice.actions;

export default managersListSlice.reducer;
