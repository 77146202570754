import { UserRoleEnum, UserStatusEnum } from './types.d';

const reportsPath = 'reports';
const vacancyPath = 'vacancy';
const companiesPath = 'companies';
const candidatesPath = 'candidates';
const recruitersPath = 'recruiters';
const hrDirectorsPath = 'hrDirectors';
const managersPath = 'managers';
const teamsPath = 'teams';
const staffPath = 'employees';

const createLink = (id: number, path: string): string => `/${path}/${id}`;

// const createVacancyCandidateLink = (
//   id: number,
//   path: string,
//   vacancyId?: number | undefined,
// ): string => `/${path}/${id}/${vacancyId}`;

export const routePaths = {
  home: '/home',
  vacancy: {
    list: `/${vacancyPath}/list`,
    create: `/${vacancyPath}/create`,
    one: `/${vacancyPath}/:id`,
    edit: `/${vacancyPath}/:id/edit`,
    createLink: (id: number) => createLink(id, vacancyPath),
  },
  candidate: {
    list: `/${candidatesPath}/list`,
    create: `/${candidatesPath}/create`,
    one: `/${candidatesPath}/:id/`,
    edit: `/${candidatesPath}/:id/edit`,
    createLink: (id: number) => createLink(id, candidatesPath),
  },
  reports: {
    recruiters: `/${reportsPath}/recruiters`,
    weekly: `/${reportsPath}/weekly`,
  },
  company: {
    one: `/${companiesPath}/:id`,
    list: `/${companiesPath}/list`,
    create: `/${companiesPath}/create`,
    edit: `/${companiesPath}/:id/edit`,
    staffList: `/${companiesPath}/:id/staff-list`,
    staffCreate: `/${companiesPath}/:id/staff-create`,
    createLink: (id: number) => createLink(id, companiesPath),
  },
  recruiter: {
    list: `/${recruitersPath}/list`,
    create: `/${recruitersPath}/create`,
    one: `/${recruitersPath}/:id`,
    edit: `/${recruitersPath}/:id/edit`,
    createLink: (id: number) => createLink(id, recruitersPath),
  },
  manager: {
    list: `/${managersPath}/list`,
    create: `/${managersPath}/create`,
    one: `/${managersPath}/:id`,
    edit: `/${managersPath}/:id/edit`,
    createLink: (id: number) => createLink(id, managersPath),
  },
  agents: '/agents',
  hrDirector: {
    list: `/${hrDirectorsPath}/list`,
    create: `/${hrDirectorsPath}/create`,
    one: `/${hrDirectorsPath}/:id`,
    edit: `/${hrDirectorsPath}/:id/edit`,
    createLink: (id: number) => createLink(id, hrDirectorsPath),
  },
  team: {
    list: `/${teamsPath}/list`,
    create: `/${teamsPath}/create`,
    one: `/${teamsPath}/:id`,
    edit: `/${teamsPath}/:id/edit`,
    createLink: (id: number) => createLink(id, teamsPath),
  },
  staff: {
    list: `/${staffPath}/list`,
    create: `/${staffPath}/create`,
    createLink: (id: number) => createLink(id, staffPath),
  },
  analytics: '/analytics',
  finance: '/finance',
  chat: '/chat',
  profile: '/my-account',
};

export const userRoles = [
  'client',
  'candidate',
  'recruiter',
  'manager',
  'admin',
  'superAdmin',
  'hrDirector',
  'agent',
];

export const vacancyStatusOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Published', value: 'Published' },
  { label: 'Hold', value: 'Hold' },
  { label: 'Closed', value: 'Closed' },
];

export const candidateStatusOptions = [
  { label: 'In progress', value: 'InProgress' },
  { label: 'Hold', value: 'Hold' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Pre-Offer', value: 'PreOffer' },
  { label: 'Offer', value: 'Offer' },
  { label: 'Offer Refused', value: 'OfferRefused' },
];

export const vacancyPublicationPeriods = {
  OneWeek: { label: 'one week', value: 'OneWeek' },
  Month: { label: 'month', value: 'Month' },
  TwoMonths: { label: 'two months', value: 'TwoMonths' },
  ThreeMonths: { label: 'three monts', value: 'ThreeMonths' },
  Year: { label: 'year', value: 'Year' },
};

export const publicationPeriods = {
  OneWeek: '1 week',
  Month: '1 month',
  TwoMonths: '2 months',
  ThreeMonths: '3 months',
  Year: 'year',
};

export const getUserStatusOptions = <T extends Function>(
  t: T,
): { label: string; value: UserStatusEnum }[] => [
  { label: t(UserStatusEnum.Active), value: UserStatusEnum.Active },
  { label: t(UserStatusEnum.Disabled), value: UserStatusEnum.Disabled },
];

export const getVacancyPublicationPeriods = <T extends Function>(
  t: T,
): any => ({
  OneWeek: { label: t('one week'), value: 'OneWeek' },
  Month: { label: t('month'), value: 'Month' },
  TwoMonths: { label: t('two months'), value: 'TwoMonths' },
  ThreeMonths: { label: t('three months'), value: 'ThreeMonths' },
  Year: { label: t('year'), value: 'Year' },
});

export const getUserStatuses = <T extends Function>(
  t: T,
): Record<string, { label: string; value: string }> => ({
  active: { label: t('active'), value: 'active' },
  disabled: { label: t('disabled'), value: 'disabled' },
});

export const getPossibleRolesTitles = <T extends Function>(
  t: T,
): Record<string, { label: string; value: UserRoleEnum }> => ({
  hrDirector: { label: t('recruiter'), value: UserRoleEnum.HRD },
  recruiter: { label: t('hr director'), value: UserRoleEnum.Recruiter },
  manager: { label: t('manager'), value: UserRoleEnum.Manager },
});

export const getPossibleRoles = (
  t: Function,
  currentRole: string,
  editorRole: string,
): { label: UserRoleEnum; value: UserRoleEnum }[] => {
  const isAdmin = editorRole === UserRoleEnum.Admin;

  const managerPossibilityRoles = isAdmin
    ? [
        {
          label: t('hr director'),
          value: UserRoleEnum.HRD,
        },
        {
          label: t(UserRoleEnum.Recruiter),
          value: UserRoleEnum.Recruiter,
        },
      ]
    : [
        {
          label: t(UserRoleEnum.Recruiter),
          value: UserRoleEnum.Recruiter,
        },
      ];

  const recruiterPossibilityRoles = isAdmin
    ? [
        {
          label: t('hr director'),
          value: UserRoleEnum.HRD,
        },
        {
          label: t(UserRoleEnum.Manager),
          value: UserRoleEnum.Manager,
        },
      ]
    : [
        {
          label: t(UserRoleEnum.Manager),
          value: UserRoleEnum.Manager,
        },
      ];

  switch (currentRole) {
    case 'hrDirector':
      return [
        {
          label: t(UserRoleEnum.Manager),
          value: UserRoleEnum.Manager,
        },
        {
          label: t(UserRoleEnum.Recruiter),
          value: UserRoleEnum.Recruiter,
        },
      ];

    case 'manager':
      return managerPossibilityRoles;

    case 'recruiter':
      return recruiterPossibilityRoles;

    default:
      return [];
  }
};

export const isNumberReg = /^[0-9]*$/;

export const MAXIMUM_ALLOWABLE_PDF_SIZE_IN_BYTES = 52428800;

export const youTubeReg =
  // eslint-disable-next-line no-irregular-whitespace
  /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\\_]*)(&(amp;)?‌​[\w\\?‌​=]*)?/;

export const urlReg =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

// eslint-disable-next-line no-irregular-whitespace, no-useless-escape
export const urlRegNew = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

export const emailReg =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const datepickerMaskMap = {
  en: '__/__/____',
  ru: '__.__.____',
};

export const googleCalendarLink = 'https://calendar.google.com/calendar/render';

export const googleCalendarDateFormatWithTime = 'yyyyMMdd\'T\'HHmmss';

export const googleCalendarDateFormat = 'yyyyMMdd';

const constants = {
  userRoles,
  vacancyStatusOptions,
  vacancyPublicationPeriods,
  publicationPeriods,
  isNumberReg,
  youTubeReg,
  urlReg,
  emailReg,
  datepickerMaskMap,
  googleCalendarLink,
  googleCalendarDateFormat
};

export default constants;
