import React, { memo, useEffect, useMemo, useRef } from 'react';

import NotificationsIcon from '@material-ui/icons/Notifications';

import { toggleNotificationsModal } from 'store/notifications/reducer';
import { useAppDispatch, useAppSelector } from 'utils/hooks/hooks';
import NotificationsModal from 'ui/components/NotificationsModal/NotificationsModal';
import { getUnreadedNotificationsCount } from 'utils/helpers';
import StyledBell from './Bell.styles';
import sound from '../../../../static/notificationSound.mp3';

const Bell: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const isInitial = useRef<boolean>(true);

  const { notificationsList } = useAppSelector(
    ({ notificationsList }) => notificationsList,
  );

  const openNotificationsModal = () => {
    dispatch(toggleNotificationsModal(true));
  };

  const closeNotificationsModal = (e: any) => {
    e.stopPropagation();
    dispatch(toggleNotificationsModal(false));
  };

  const anchorRef = useRef(null);

  const unreadedNotifictions = getUnreadedNotificationsCount(notificationsList);
  const unreadedCount = unreadedNotifictions.length;

  const notificationsCounter = unreadedCount > 99 ? '99+' : unreadedCount;

  const isCounterVisible = Boolean(unreadedCount);
  const audio = useMemo(() => new Audio(sound), []);
  useEffect(() => {
    if (unreadedCount === 0) {
      document.title = 'HR Network';
      return;
    }

    document.title = `(${unreadedCount}) HR Network`;
    if (!isInitial.current) {
      audio.play();
    }
    isInitial.current = false;
  }, [audio, unreadedCount]);

  return (
    <>
      <StyledBell
        onClick={openNotificationsModal}
        ref={anchorRef}
        isCounterVisible={isCounterVisible}
      >
        <NotificationsIcon />
        <div className="notifications-counter">{notificationsCounter}</div>
      </StyledBell>
      <NotificationsModal
        ref={anchorRef}
        handleClose={closeNotificationsModal}
      />
    </>
  );
};

export default memo(Bell);
