const citiesRu = {
  Архангельск: 'Архангельск',
  Астрахань: 'Астрахань',
  Балашиха: 'Балашиха',
  Барнаул: 'Барнаул',
  Белгород: 'Белгород',
  Брянск: 'Брянск',
  'Великий Новгород': 'Великий Новгород',
  Владивосток: 'Владивосток',
  Владикавказ: 'Владикавказ',
  Владимир: 'Владимир',
  Волгоград: 'Волгоград',
  Вологда: 'Вологда',
  Воронеж: 'Воронеж',
  Грозный: 'Грозный',
  Домодедово: 'Домодедово',
  Екатеринбург: 'Екатеринбург',
  Жуковский: 'Жуковский',
  Иваново: 'Иваново',
  Ижевск: 'Ижевск',
  Иркутск: 'Иркутск',
  'Йошкар-Ола': 'Йошкар-Ола',
  Казань: 'Казань',
  Калининград: 'Калининград',
  Калуга: 'Калуга',
  Кемерово: 'Кемерово',
  Киров: 'Киров',
  Коломна: 'Коломна',
  Королёв: 'Королёв',
  Красногорск: 'Красногорск',
  Краснодар: 'Краснодар',
  Красноярск: 'Красноярск',
  Курск: 'Курск',
  Липецк: 'Липецк',
  Люберцы: 'Люберцы',
  Магнитогорск: 'Магнитогорск',
  Махачкала: 'Махачкала',
  Москва: 'Москва',
  'Московская область': 'Московская область',
  Мурманск: 'Мурманск',
  Мытищи: 'Мытищи',
  'Набережные Челны': 'Набережные Челны',
  'Нижний Новгород': 'Нижний Новгород',
  Новокузнецк: 'Новокузнецк',
  Новороссийск: 'Новороссийск',
  Новосибирск: 'Новосибирск',
  Ногинск: 'Ногинск',
  Одинцово: 'Одинцово',
  Омск: 'Омск',
  Оренбург: 'Оренбург',
  Орёл: 'Орёл',
  Пенза: 'Пенза',
  Пермь: 'Пермь',
  Петрозаводск: 'Петрозаводск',
  Подольск: 'Подольск',
  Псков: 'Псков',
  Пятигорск: 'Пятигорск',
  Раменское: 'Раменское',
  'Ростов-на-Дону': 'Ростов-на-Дону',
  Рязань: 'Рязань',
  Самара: 'Самара',
  'Санкт-Петербург': 'Санкт-Петербург',
  Саратов: 'Саратов',
  Севастополь: 'Севастополь',
  'Сергиев Посад': 'Сергиев Посад',
  Серпухов: 'Серпухов',
  Симферополь: 'Симферополь',
  Смоленск: 'Смоленск',
  Сочи: 'Сочи',
  Ставрополь: 'Ставрополь',
  Сургут: 'Сургут',
  Таганрог: 'Таганрог',
  Тамбов: 'Тамбов',
  Тверь: 'Тверь',
  Тольятти: 'Тольятти',
  Томск: 'Томск',
  Тула: 'Тула',
  Тюмень: 'Тюмень',
  'Улан-Удэ': 'Улан-Удэ',
  Ульяновск: 'Ульяновск',
  Уфа: 'Уфа',
  Хабаровск: 'Хабаровск',
  Химки: 'Химки',
  Чебоксары: 'Чебоксары',
  Чита: 'Чита',
  Энгельс: 'Энгельс',
  Ярославль: 'Ярославль',
  Винница: 'Винница',
  Днепр: 'Днепр',
  Запорожье: 'Запорожье',
  Киев: 'Киев',
  Львов: 'Львов',
  Одесса: 'Одесса',
  Полтава: 'Полтава',
  Харьков: 'Харьков',
  Хмельницкий: 'Хмельницкий',
  Черкассы: 'Черкассы',
  Гродно: 'Гродно',
  Минск: 'Минск',
  Быдгощ: 'Быдгощ',
  Варшава: 'Варшава',
  Ворцлав: 'Ворцлав',
  Гданьск: 'Гданьск',
  Краков: 'Краков',
  Лодзь: 'Лодзь',
  Люблин: 'Люблин',
  Познань: 'Познань',
  Ереван: 'Ереван',
  Beijing: 'Пекин',
  Chengdu: 'Чэнду',
  Chongqing: 'Чунцин',
  Dongguan: 'Дунгуань',
  Guangzhou: 'Гуанчжоу',
  Shanghai: 'Шанхай',
  Shenyang: 'Шэньян',
  Shenzhen: 'Шэньчжэ́нь',
  Tianjin: 'Тяньцзинь',
  Wuhan: 'Ухань',
  'Kato Polemidia': 'Като Полемидия',
  Lacatamia: 'Лакатамия',
  Larnaca: 'Ларнака',
  Limassol: 'Лимассол',
  Nicosia: 'Никосия',
  Paphos: 'Пафос',
  Strovolos: 'Строволос',
  Bishkek: 'Бишкек',
  Osh: 'Ош',
  Daugavpils: 'Даугавпилс',
  Jekabpils: 'Екабпилс',
  Jelgava: 'Елгава',
  Jurmala: 'Юрмала',
  Liepaja: 'Лиепая',
  Ogre: 'Огре',
  Rezekne: 'Резекне',
  Riga: 'Рига',
  Sigulda: 'Сигулда',
  Valmiera: 'Валмиера',
  Ventspils: 'Вентспилс',
  Kalookan: 'Калоокан',
  'Las Pinas': 'Лас-Пиньяс',
  Makati: 'Макати',
  Manila: 'Манила',
  Marikina: 'Марикина',
  Mountainloop: 'Mountainloop',
  Parañaque: 'Параньяке',
  Pasig: 'Пасиг',
  'Queson city': 'Кесон-Сити',
  Taguig: 'Тагиг',
  Valenzuela: 'Валенсуэла',
  Chiayi: 'Цзяи',
  Hsinchu: 'Синьчжу',
  Jilong: 'Цзилун',
  Kaohsiung: 'Гаосюн',
  Tainan: 'Тайнань',
  Taipei: 'Тайбэй',
};

export default citiesRu;
