import { createSlice } from '@reduxjs/toolkit';

import { CandidateType, SelectType, TeamOffer, TeamType } from 'utils/types';

const getInitialStore = () => ({
  teamsList: [] as TeamType[],
  team: null as unknown as TeamType | null,
  teamOfferLoading: true,
  count: 0,
  isEditModalOpen: false,
  teamId: null as string | null,
  sortBy: 'createdAt',
  sortDirection: 'reverse',
  sortSelect: { value: ['createdAt', 'reverse'], label: 'latest' },
  paginationPerPage: 20,
  paginationPage: 1,
  hasMore: true,
  filter: {
    manager: null as unknown as SelectType,
    search: '',
    status: [{ value: 'active', label: 'active' }],
  },
});

const initialStore = getInitialStore();

export type StoreType = typeof initialStore;

export const sliceName = 'teamsList';

const teamsListSlice = createSlice({
  name: sliceName,
  initialState: getInitialStore(),
  reducers: {
    setTeamsList: (
      store,
      { payload }: { payload: { teams: TeamType[]; count: number } },
    ) => ({
      ...store,
      teamsList: [...store.teamsList, ...payload.teams],
      count: payload.count,
    }),
    addTeamToList: (
      store,
      { payload }: { payload: { team: TeamType; count: number } },
    ) => ({
      ...store,
      teamsList: [payload.team, ...store.teamsList],
      count: payload.count,
    }),
    setTeam: (store, { payload }: { payload: TeamType | null }) => ({
      ...store,
      team: payload,
    }),
    setTeamCandidates: (store, { payload }: { payload: CandidateType[] }) => {
      store.team = store.team ? { ...store.team, teamCandidates: payload} : null;
    },
    updateOneTeam: (store, { payload }: { payload: TeamType | null }) => ({
      ...store,
      teamsList: store.teamsList!.map((team: TeamType) =>
        team!.id === payload?.id ? { ...team, ...payload } : team,
      ),
      team:
        store.team !== null && store.team?.id === payload?.id
          ? { ...store.team, ...payload }
          : store.team,
    }),
    updateSearch: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        search: payload,
      },
    }),
    updateSort: (store, { payload: { value, label } }) => ({
      ...store,
      sortBy: value[0],
      sortDirection: value[1],
      sortSelect: {
        value,
        label,
      },
    }),
    updateFilter: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        [payload.name]: payload.value,
      },
    }),
    updatePage: (store) => ({
      ...store,
      paginationPage: store.paginationPage + 1,
    }),
    resetPage: (store) => ({
      ...store,
      paginationPage: 1,
    }),
    clearFilter: (store) => ({
      ...store,
      filter: getInitialStore().filter,
    }),
    resetTeamsList: (store) => ({
      ...store,
      teamsList: [],
      count: 0,
    }),
    isHasMore: (store, { payload }) => ({
      ...store,
      hasMore: payload >= store.paginationPerPage,
    }),
    toggleIsEditOpen: (store, { payload }) => ({
      ...store,
      isEditModalOpen:
        typeof payload === 'boolean' ? payload : !store.isEditModalOpen,
    }),
    setTeamId: (store, { payload }) => ({
      ...store,
      teamId: payload,
    }),
    setTeamOffer: (store, { payload }: { payload: TeamOffer }) => {
      store.team = store.team ? { ...store.team, teamOffer: payload} : null;
    },
    updateOfferInfo: (store, { payload } : { payload: Partial<TeamOffer> }) => {
      const newTeamOffer = { ...store.team?.teamOffer, ...payload } as TeamOffer;
      store.team = store.team ? { ...store.team, teamOffer: newTeamOffer} : null;
    },
    setTeamOfferLoading: (store, { payload }) => {
      store.teamOfferLoading = payload;
    },
    setTeamOfferRecruiters: (store, { payload }) => {
      store.team = store.team ? { ...store.team, teamOfferRecruiters: payload } : null;
    },
    clearTeam: (store) => {
      store.team = null;
      store.teamId = null;
    },
  },
});

export const {
  setTeamsList,
  setTeam,
  resetTeamsList,
  updateSearch,
  updateSort,
  updateFilter,
  clearFilter,
  updatePage,
  resetPage,
  isHasMore,
  updateOneTeam,
  addTeamToList,
  toggleIsEditOpen,
  setTeamId,
  setTeamCandidates,
  setTeamOffer,
  setTeamOfferLoading,
  updateOfferInfo,
  setTeamOfferRecruiters,
  clearTeam,
} = teamsListSlice.actions;

export default teamsListSlice.reducer;
