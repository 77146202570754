import React, { memo } from 'react';
import { Redirect } from 'react-router-dom';

import { routePaths } from 'utils/constants';
import { useAppSelector } from 'utils/hooks';

// TODO - generic
export const accessCheck = (user: any, roles: string | string[]) => {
  if (user && roles === 'any') {
    return true;
  }

  let required = [roles];
  if (Array.isArray(roles)) {
    required = roles;
  }

  return required.includes(user?.role || 'none');
};

export const protector = (Page: React.ElementType, roles: string[] | string) =>
  memo((props) => {
    const user = useAppSelector(({ main }) => main.user);

    const isRoleAccepted = accessCheck(user, roles);
    const isActive = user?.status === 'active';

    if (!isRoleAccepted || (user && !isActive)) {
      return <Redirect to={routePaths.home} />;
    }

    return <Page {...props} />;
  });

export const SimpleRoleChecker = (props: any): any => {
  const user = useAppSelector(({ main }) => main.user);

  const isRoleAccepted = accessCheck(user, props.roles);

  if (!isRoleAccepted) {
    return null;
  }
  return props.children;
};

export const RoleChecker = memo(SimpleRoleChecker);

export default protector;
