/* eslint-disable react/no-danger */
import React, { FC } from 'react';

import { useAppDispatch } from 'utils/hooks/hooks';
import { postLinkedin } from 'store/discount/thunks';

import StyledVariant from './Variant.style';
import { LinkedinButton } from '../../DiscountPopup.style';

type Props = {
  id: number;
  tags: string;
  title: (vacancyTitle?: string) => string;
  onChooseVariant: (variant: number) => void;
  description: string;
  ending?: string;
  vacancyTitle?: string;
  companyId: number;
}

const VariantCard: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const onPost = () => {
    dispatch(postLinkedin(props.companyId));
    props.onChooseVariant(props.id);
  }

  return (
    <StyledVariant className='variant'>
      <p className='variant__tags'>{props.tags}</p>
      <div
        className='variant__title'
        dangerouslySetInnerHTML={{ __html: props.title(props.vacancyTitle) }}
      />
      <div
        className='variant__description'
        dangerouslySetInnerHTML={{ __html: props.description }}
      />
      <div
        className='variant__ending'
        dangerouslySetInnerHTML={{ __html: props.ending || '' }}
      />
  
      <LinkedinButton isVariant onClick={onPost}>
        post a variant in Linkedin
      </LinkedinButton>
    </StyledVariant>
  )
};

export default VariantCard;
