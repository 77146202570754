import { createSlice } from '@reduxjs/toolkit';

import { CompanyType, SelectType } from 'utils/types';

const getInitialStore = () => ({
  companiesList: null as Record<CompanyType['id'], CompanyType> | null,
  orderedIds: [] as number[],
  company: null as unknown as CompanyType,
  count: 0,
  sortBy: 'createdAt',
  sortDirection: 'reverse',
  sortSelect: { value: ['createdAt', 'reverse'], label: 'latest' },
  paginationPerPage: 20,
  paginationPage: 1,
  hasMore: true,
  filter: {
    status: [{ label: 'active', value: 'active' }],
    manager: null as unknown as SelectType[],
    search: '',
  },
});

const initialStore = getInitialStore();

export type StoreType = typeof initialStore;

export const sliceName = 'companiesList';

const companiesListSlice = createSlice({
  name: sliceName,
  initialState: getInitialStore(),
  reducers: {
    setCompaniesList: (store, { payload }) => ({
      ...store,
      companiesList: { ...(store.companiesList as any), ...payload.companies },
      count: payload.count,
    }),
    addOneCompanyToStart: (store, { payload }) => ({
      ...store,
      companiesList: { ...payload, ...(store.companiesList as any) },
      count: payload.count + 1,
    }),
    setOrderedIdsToStart: (store, { payload }) => ({
      ...store,
      orderedIds: [payload, ...store.orderedIds],
    }),
    setCompany: (store, { payload }) => ({
      ...store,
      company: payload,
    }),
    update: (store, { payload }) => ({
      ...store,
      companiesList: {
        ...store.companiesList,
        [payload.id]: payload,
      },
    }),
    updateSearch: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        search: payload,
      },
    }),
    updateSort: (store, { payload: { value, label } }) => ({
      ...store,
      sortBy: value[0],
      sortDirection: value[1],
      sortSelect: {
        value,
        label,
      },
    }),
    updateFilter: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        [payload.name]: payload.value,
      },
    }),
    updatePage: (store) => ({
      ...store,
      paginationPage: store.paginationPage + 1,
    }),
    resetPage: (store) => ({
      ...store,
      paginationPage: 1,
    }),
    clearFilter: (store) => ({
      ...store,
      filter: getInitialStore().filter,
    }),
    resetCompaniesList: (store) => ({
      ...store,
      companiesList: {},
      count: 0,
    }),
    isHasMore: (store, { payload }) => ({
      ...store,
      hasMore: payload >= store.paginationPerPage,
    }),
    setOrderedIds: (store, { payload }) => ({
      ...store,
      orderedIds: [...store.orderedIds, ...payload],
    }),
    resetOrderedIds: (store) => ({
      ...store,
      orderedIds: [],
    }),
  },
});

export const {
  setCompaniesList,
  setCompany,
  resetCompaniesList,
  updateSearch,
  updateSort,
  updateFilter,
  clearFilter,
  updatePage,
  resetPage,
  isHasMore,
  update,
  setOrderedIds,
  resetOrderedIds,
  addOneCompanyToStart,
  setOrderedIdsToStart,
} = companiesListSlice.actions;

export default companiesListSlice.reducer;
