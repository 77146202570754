import { createSlice } from '@reduxjs/toolkit';

import {
  CandidateCountryType,
  CompanyType,
  SelectType,
  SkillType,
  VacancyType,
} from 'utils/types';

const getInitialStore = () => ({
  companiesOptionsList: [] as CompanyType[],
  vacanciesCountriesList: [] as SelectType[],
  vacanciesCitiesList: [] as SelectType[],
  candidatesCountriesList: [] as CandidateCountryType[],
  vacanciesOptionsList: [] as VacancyType[],
  candidatesSkillsOptionsList: [] as SkillType[],
  candidateSpecializationsOptionsList: [] as VacancyType[],
  teamsOptionsList: [] as SelectType[],
});

const initialStore = getInitialStore();

export type StoreType = typeof initialStore;

export const sliceName = 'filterOptionsList';

const optionsListSlice = createSlice({
  name: sliceName,
  initialState: getInitialStore(),
  reducers: {
    setCompaniesOptionsList: (store, { payload }) => ({
      ...store,
      companiesOptionsList: payload,
    }),
    setVacanciesCountriesList: (store, { payload }) => ({
      ...store,
      vacanciesCountriesList: [
        ...(store.vacanciesCountriesList as SelectType[]),
        ...payload.vacanciesCountriesList,
      ],
    }),

    setVacanciesCitiesList: (store, { payload }) => ({
      ...store,
      vacanciesCitiesList: [
        ...(store.vacanciesCitiesList as SelectType[]),
        ...payload.vacanciesCitiesList,
      ],
    }),

    setCandidatesCountriesList: (store, { payload }) => ({
      ...store,
      candidatesCountriesList: payload,
    }),

    setVacanciesOptionsList: (store, { payload }) => ({
      ...store,
      vacanciesOptionsList: [
        ...(store.vacanciesOptionsList as VacancyType[]),
        ...payload,
      ],
    }),

    setCandidatesSkillsOptionsList: (store, { payload }) => ({
      ...store,
      candidatesSkillsOptionsList: [
        ...(store.candidatesSkillsOptionsList as VacancyType[]),
        ...payload,
      ],
    }),

    setCandidatesSpecializationsOptionsList: (store, { payload }) => ({
      ...store,
      candidateSpecializationsOptionsList: [
        ...(store.candidateSpecializationsOptionsList as VacancyType[]),
        ...payload,
      ],
    }),

    resetVacanciesOptionsList: (store) => ({
      ...store,
      vacanciesOptionsList: [],
    }),

    setTeams: (store, { payload }) => ({
      ...store,
      teamsOptionsList: payload,
    }),
  },
});

export const {
  setCompaniesOptionsList,
  setVacanciesCountriesList,
  setVacanciesCitiesList,
  setCandidatesCountriesList,
  setVacanciesOptionsList,
  resetVacanciesOptionsList,
  setCandidatesSkillsOptionsList,
  setCandidatesSpecializationsOptionsList,
  setTeams,
} = optionsListSlice.actions;

export default optionsListSlice.reducer;
