import React from 'react';

const VacancyIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.74981 1.18335C6.87204 1.18335 5.34981 2.70558 5.34981 4.58335C5.34981 4.81347 5.16326 5.00002 4.93314 5.00002H3.43204C2.4234 5.00002 1.5726 5.75109 1.44749 6.75195L1.0483 9.94549C0.924845 10.9331 1.54639 11.8612 2.50662 12.1231L3.88871 12.5L8.94734 13.8796C9.63641 14.0676 10.3632 14.0676 11.0523 13.8796L16.1109 12.5L17.493 12.1231C18.4532 11.8612 19.0748 10.9331 18.9513 9.94549L18.5521 6.75195C18.427 5.75109 17.5762 5.00002 16.5676 5.00002H15.0665C14.8364 5.00002 14.6498 4.81347 14.6498 4.58335C14.6498 2.70558 13.1276 1.18335 11.2498 1.18335H8.74981ZM12.4331 5.00002C12.6633 5.00002 12.8498 4.81347 12.8498 4.58335C12.8498 3.69969 12.1335 2.98335 11.2498 2.98335H8.74981C7.86616 2.98335 7.14981 3.69969 7.14981 4.58335C7.14981 4.81347 7.33636 5.00002 7.56648 5.00002H12.4331ZM2.11194 13.5702C2.00303 13.5405 1.88441 13.5842 1.83849 13.6873C1.72793 13.9356 1.66648 14.2107 1.66648 14.5V16.3334C1.66648 17.4379 2.56191 18.3334 3.66648 18.3334H16.3331C17.4377 18.3334 18.3331 17.4379 18.3331 16.3333V14.5C18.3331 14.2107 18.2717 13.9357 18.1611 13.6873C18.1152 13.5842 17.9966 13.5405 17.8877 13.5702L11.447 15.3268C10.4995 15.5852 9.50013 15.5852 8.55267 15.3268L2.11194 13.5702Z"
      // fill="#262F56"
    />
  </svg>
);

export default VacancyIcon;
