const variants = [
  {
    id: 1,
    tags: '#hiring #techjobs #ITrecruitment #hrnetwork',
    title: () => 'Excited to share our excellent experience with <a href="https://www.linkedin.com/company/hr-network/" class="variant__title--link">https://www.linkedin.com/company/hr-network/</a>!',
    description: 'They were instrumental in helping us find a fantastic new team member quickly and efficiently.',
    ending: 'Thank you, <span class="variant__ending--link">www.hrnetwork.club</span>, for your outstanding support!',
  },
  {
    id: 2,
    tags: '#recommendation #recruiting #IT #hrnetwork',
    title: (vacancyTitle?: string) => `We've just successfully filled a vacancy ${vacancyTitle} with <a href="https://www.linkedin.com/company/hr-network/" class="variant__title--link">https://www.linkedin.com/company/hr-network/</a> and I highly recommend their services.`,
    description: 'They demonstrated a high level of professionalism and truly understand the needs of IT companies.',
    ending: 'Great job, <span class="variant__ending--link">www.hrnetwork.club</span> team!',
  },
  {
    id: 3,
    tags: '#recommendation #recruiting #IT #hrnetwork',
    title: (vacancyTitle?: string) => `We've just successfully filled a vacancy ${vacancyTitle} with <a href="https://www.linkedin.com/company/hr-network/" class="variant__title--link">https://www.linkedin.com/company/hr-network/</a> and I highly recommend their services.`,
    description: 'Thanks to their approach, we found the perfect candidate in no time.',
    ending: 'Great job, <span class="variant__ending--link">www.hrnetwork.club</span> team!',
  },
]

export default variants;
