import { createAsyncThunk } from '@reduxjs/toolkit';
import crmNotificationsApi from 'api/crmNotificationsApi';
import { setNotificationList } from './reducer';

const getNotificationsList = createAsyncThunk(
  'notificationsList/getNotificationsList',
  async (arg: number, { dispatch }) => {
    try {
      const response = await crmNotificationsApi.getList(+arg);

      const formattedNotificationsList = response.map((i: any) => ({
        ...i.data,
        id: i.id,
        iconType: i.iconType,
        isRead: i.isRead,
        type: i.type,
        createdAt: i.createdAt,
        // TODO !important!!!!!!!!!!!
        authorId: i.authorId,
      }));

      dispatch(setNotificationList(formattedNotificationsList));
    } catch (err) {
      dispatch(setNotificationList([]));
    }
  },
);

export default getNotificationsList;
