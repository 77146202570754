import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';

import GlobalStyles from 'ui/styles/GlobalStyles';
import StyledComponentsTheme from 'ui/styles/StyledComponentsTheme';
import MaterialTheme from 'ui/styles/MaterialTheme';

import store from 'store';
import App from './App';


ReactDOM.render(
  <StoreProvider store={store}>
    <BrowserRouter>
      <StyledComponentsTheme>
        <MaterialTheme>
          <>
            <GlobalStyles />

            <App />
          </>
        </MaterialTheme>
      </StyledComponentsTheme>
    </BrowserRouter>
  </StoreProvider>,
  document.getElementById('root'),
);
