import { darken, lighten } from 'polished';

const primaryColor = '#000000';
const secondaryColor = '#363636';
const primaryBackgroundColor = '#FFFFFF';
const secondaryBackgroundColor = '#F8F8F8';
const mainBorderColor = '1px solid #DADADA';
const secondaryBorderColor = '1px solid #E5E5E5';

const colors = {
  primaryGray: '#E9E9E9',
  secondaryGray: '#9b9b9b',
  preOfferColor: '#0963d2',
  offerColor: '#06FDFF',
  goldenColor: '#FCC201',
  rejectColor: '#C85037',
};

const screen = {
  sm: 425,
  md: 768,
  lg: 1024,
  xLg: 1920,
};

const theme = {
  colors: {
    ...colors,
    pageBackground: primaryBackgroundColor,
    blockBackground: secondaryBackgroundColor,
    mainText: primaryColor,
    secondaryText: secondaryColor,
    mainBorder: mainBorderColor,
    secondaryBorder: secondaryBorderColor,
    navbar: {
      background: colors.primaryGray,
      backgroundDark: 'black',
      text: 'black',
      textLight: 'white',
    },
    primary: {
      main: primaryColor,
      light: lighten(0.1, primaryColor),
      dark: darken(0.1, primaryColor),
      contrastText: '#FFFFFF',
    },
  },
  padding: {
    sSm: '2px 5px',
    sm: '3px 10px',
    md: '5px 15px',
    lg: '10px 20px',
    xLg: '15px 30px',
  },
  margin: {
    formInput: 30,
    submitButton: 40,
  },
  font: {
    size: {
      globalValue: '16px',
      xSm: '0.75rem',
      sm: '0.88rem',
      md: '1rem',
      xMd: '1.1rem',
      lg: '1.5rem',
      xLg: '2rem',
    },
    family: {
      main: "'Montserrat', sans-serif",
      // main: 'Montserrat',
      secondary: "'Noto Sans', sans-serif",
    },
    weight: {
      xSm: 300,
      sm: 400,
      md: 500,
      lg: 700,
      xLg: 900,
    },
  },
  screen,
  transitionValue: 0.15,
  transition: '0.15s',
  header: {
    minHeight: '58px',
    backgroundColor: '#F8F8F8',
  },
  sidebar: {
    width: {
      open: '233px',
      closed: '66px',
    },
    backgroundColor: colors.primaryGray,
  },
  respond: (screenSize: string, content: string) => {
    const breakpoint = Object.entries(screen).find(
      ([breakpointName]) => screenSize === breakpointName,
    );
    if (breakpoint) {
      const [, breakpointValue] = breakpoint;
      return `@media (max-width: ${breakpointValue}px) {
      ${content}
    }`;
    }
    return '';
  },
};

export default theme;
