import styled from 'styled-components';

const UnstyledButton = styled.button<{
  isRoleAccepted?: boolean;
  isMyCandidate?: boolean;
  pointer?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  background: none;
  color: ${({ color }) => color || 'inherit'};
  background: 'inherit';
  border: none;
  padding: 0;
  font: inherit;
  cursor: ${({ isRoleAccepted, isMyCandidate, pointer }) =>
    // eslint-disable-next-line no-nested-ternary
    pointer ? 'pointer': isRoleAccepted && isMyCandidate ? 'pointer' : 'cursor'};
  outline: inherit;

  /* Fix bug with dropdown overlay on candidates block  */
  & + div {
    z-index: 1;
  }
`;

export default UnstyledButton;
