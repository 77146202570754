import { createSlice } from '@reduxjs/toolkit';
import { VacancyType } from 'utils/types';

const getInitialStore = () => ({
  isOpen: false,
  vacancy: null as VacancyType | null,
  discount: 0,
});

const initialStore = getInitialStore();
export type StoreType = typeof initialStore;
export const sliceName = 'discountPopup';

const discountPopupSlice = createSlice({
  name: sliceName,
  initialState: getInitialStore(),
  reducers: {
    togglePopup: (store, { payload }) => {
      store.isOpen = payload;
    },
    setVacancy: (store, { payload }) => {
      store.vacancy = payload;
    },
    setDiscount: (store, { payload }) => {
      store.discount = payload;
    },
  },
});

export const {
  togglePopup,
  setVacancy,
  setDiscount,
} = discountPopupSlice.actions;

export default discountPopupSlice.reducer;
