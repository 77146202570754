import { darken } from 'polished';
import styled from 'styled-components';
// import theme from "ui/styles/StyledComponentsTheme/themes/main";

const padding = 15;
// temp disabled
// const closedPadding = (+theme.sidebar.width.closed.replace(/px/, '') - 30) / 2;

type StyledMainPropType = {
  isSidebarOpen: boolean;
};

const StyledMain = styled.main<StyledMainPropType>`
  background: ${({ theme }) => theme.colors.navbar.background};
  color: ${({ theme }) => theme.colors.navbar.text};
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  justify-content: baseline;
  padding: ${padding}px 0;
  overflow: hidden;
  overflow-y: auto;

  a {
    display: flex;
    align-items: center;

    padding: 14px 26px 11px 26px;
    transition: ${({ theme }) => theme.transition};

    :hover {
      background-color: ${({ theme }) =>
        darken(0.05, theme.colors.navbar.background)};
    }
  }

  .nav-item-title {
    opacity: ${({ isSidebarOpen }) => (isSidebarOpen ? 1 : 0)};
    white-space: nowrap;
  }

  .nav-item-count {
    padding-left: 20px;
    font-size: ${({ theme }) => theme.font.size.sm};
    text-decoration: underline;
    color: #60c837;
  }

  .current-link {
    color: ${({ theme }) => theme.colors.primary.main};
    svg {
      fill: ${({ theme }) => theme.colors.primary.main};
    }
    font-weight: ${({ theme }) => theme.font.weight.lg};
    background: #cdcdcd;
  }

  && svg {
    cursor: pointer;
    transition: ${({ theme }) => theme.transition};
    margin-right: 15px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    max-height: 20px;
  }

  .nav-item-has-notification {
    svg {
      color: #60c837;
      fill: #60c837;
    }
  }

  .sidebar-show-icon {
    padding: 14px 26px 11px 26px;
    transition: ${({ theme }) => theme.transition};

    img {
      cursor: pointer;
    }
  }
`;

export default StyledMain;
