import styled from 'styled-components';
import { Paper } from '@material-ui/core';

const StyledPaper = styled(Paper)<{ width?: number, justify?: string }>`
  && {
    position: relative;
    background: white;
    border-radius: 25px;
    box-shadow: 0px 4px 28px 0px rgba(89, 89, 89, 0.12);
    min-height: 339px;
    max-width: ${(props) => props.width || 337}px;
  }

  .unicorn-like {
    &__animation-container {
      display: flex;
      justify-content: ${(props) => props.justify || 'normal'};

      div {
        margin: 0 !important;
        padding-left: 30px;
      }
    }

    &__animation-svg {
      position: absolute;
      top: -159px;
      height: 339px !important;
      width: 235px !important;
      overflow: visible;
    }

    &__close-btn {
      position: absolute;
      right: -44px;
      top: 0;
    }

    &__info {
      padding: 30px 40px;
    }

    &__info-title {
      font-weight: 600;
      line-height: 28px;
      font-size: 17px;
      color: #363636;
      margin-bottom: 15px;
    }

    &__checkbox {
      font-size: 14px;
      line-height: 28px;
    }
  }
`

export default StyledPaper;
