/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'utils/hooks';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';

import crmNotificationsApi from 'api/crmNotificationsApi';
import {
  toggleNotificationsModal,
  markNotificationsAsRead,
} from 'store/notifications/reducer';
import { getUnreadedNotificationsCount } from 'utils/helpers';
import {
  StyledPaper,
  StyledPopper,
} from './components/NotificationsModal.styles';
import SettingsIcon from './components/SettingsIcon';
import NotificationRecord from './components/NotificationRecord/NotificationRecord';

import NoInfo from './components/NoInfo';
import UnstyledButton from '../UnstyledButton';

const NotificationsModal: React.FC<any> = React.forwardRef(
  (props, anchorRef) => {
    const dispatch = useAppDispatch();
    const { isNotificationsModalOpen, notificationsList } = useAppSelector(
      ({ notificationsList }) => notificationsList,
    );
    const { user } = useAppSelector(({ main }) => main);
    // @ts-ignore
    const { t } = useTranslation();

    // @ts-ignore
    const unreadedNotifications = getUnreadedNotificationsCount(notificationsList);

    const markAllAsRead = async () => {
      await crmNotificationsApi.markAsRead(user.id);

      dispatch(markNotificationsAsRead(readNotifications(notificationsList)));
      dispatch(toggleNotificationsModal(false));
    };

    // TODO generic type;
    // TODO separate to another file
    const readNotifications = (arr: any[]) => {
      const result = arr
        // @ts-ignore
        .map((i) => ({ ...i, isRead: true }));
      // update local state logic here;

      return result;
    };

    return (
      <>
        <StyledPopper
          open={isNotificationsModalOpen}
          // @ts-ignore
          anchorEl={anchorRef?.current}
          transition
          onClick={(event) => event.stopPropagation()}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'left top',
              }}
            >
              <StyledPaper>
                <ClickAwayListener onClickAway={props.handleClose}>
                  <div className="inner">
                    <div className="inner-header">
                      <p className="inner-header-notifications">
                        {t('notifications')}
                      </p>
                      <p className="inner-header-settings">
                        <SettingsIcon />
                        {t('settings')}
                      </p>
                    </div>

                    <div className="inner-body">
                      {notificationsList.length ? (
                        notificationsList.map((record, index) => (
                          <NotificationRecord key={index} record={record} />
                        ))
                      ) : (
                        <NoInfo />
                      )}

                      {/* <NotificationRecord record="text"/> */}
                    </div>

                    <div className="inner-footer">
                      <p className="inner-footer-see-all">{t('see all')}</p>
                      {Boolean(unreadedNotifications.length) && (
                        <UnstyledButton pointer onClick={markAllAsRead}>
                          {t('mark all as read')}
                        </UnstyledButton>
                      )}
                    </div>
                  </div>
                </ClickAwayListener>
              </StyledPaper>
            </Grow>
          )}
        </StyledPopper>
      </>
    );
  },
);

export default NotificationsModal;
