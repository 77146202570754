import { createSlice } from '@reduxjs/toolkit';

import { HrDirectorType } from 'utils/types';

const getInitialStore = () => ({
  hrDirectorsList: [] as HrDirectorType[],
  hrDirector: null as unknown as HrDirectorType,
  count: 0,
  sortBy: 'createdAt',
  sortDirection: 'reverse',
  sortSelect: { value: ['createdAt', 'reverse'], label: 'latest' },
  paginationPerPage: 20,
  paginationPage: 1,
  hasMore: true,
  filter: {
    search: '',
    status: [{ label: 'active', value: 'active' }],
  },
});

const initialStore = getInitialStore();

export type StoreType = typeof initialStore;

export const sliceName = 'hrDirectorsList';

const hrDirectorsListSlice = createSlice({
  name: sliceName,
  initialState: getInitialStore(),
  reducers: {
    setHrDirectorsList: (store, { payload }) => ({
      ...store,
      hrDirectorsList: [
        ...(store.hrDirectorsList as HrDirectorType[]),
        ...payload.hrDirectors,
      ],
      count: payload.count,
    }),
    addHrDirectorToList: (
      store,
      { payload }: { payload: { hrDirector: HrDirectorType; count: number } },
    ) => ({
      ...store,
      hrDirectorsList: [payload.hrDirector, ...store.hrDirectorsList],
      count: payload.count,
    }),
    setHrDirector: (store, { payload }) => ({
      ...store,
      hrDirector: payload,
    }),
    updateOneHrDirector: (store, { payload }) => ({
      ...store,
      hrDirectorsList: store.hrDirectorsList!.map(
        (hrDirector: HrDirectorType) =>
          hrDirector!.id === payload.id
            ? { ...hrDirector, ...(payload as HrDirectorType) }
            : hrDirector,
      ),
      hrDirector:
        store.hrDirector !== null && store.hrDirector?.id === payload.id
          ? { ...store.hrDirector, ...payload }
          : store.hrDirector,
    }),
    updateSearch: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        search: payload,
      },
    }),
    updateSort: (store, { payload: { value, label } }) => ({
      ...store,
      sortBy: value[0],
      sortDirection: value[1],
      sortSelect: {
        value,
        label,
      },
    }),
    updateFilter: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        [payload.name]: payload.value,
      },
    }),
    updatePage: (store) => ({
      ...store,
      paginationPage: store.paginationPage + 1,
    }),
    resetPage: (store) => ({
      ...store,
      paginationPage: 1,
    }),
    clearFilter: (store) => ({
      ...store,
      filter: getInitialStore().filter,
    }),
    resetHrDirectorsList: (store) => ({
      ...store,
      hrDirectorsList: [],
      count: 0,
    }),
    isHasMore: (store, { payload }) => ({
      ...store,
      hasMore: payload >= store.paginationPerPage,
    }),
  },
});

export const {
  setHrDirectorsList,
  setHrDirector,
  resetHrDirectorsList,
  updateSearch,
  updateSort,
  updateFilter,
  clearFilter,
  updatePage,
  resetPage,
  isHasMore,
  updateOneHrDirector,
  addHrDirectorToList,
} = hrDirectorsListSlice.actions;

export default hrDirectorsListSlice.reducer;
