import React, { memo, Suspense } from 'react';

const LazyWrapper = (Component: React.ElementType) => {
  const Wrapped = (props: any) => (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );

  return memo(Wrapped);
};

export default LazyWrapper;
