import styled from "styled-components";

const StyledVariant = styled.div`
  padding: 2.1vh;
  background: white;
  box-shadow: 0px 4px 28px 0px #5959591F;
  border-radius: 25px;
  box-sizing: border-box;
  
  .variant {
    &__tags {
      font-size: 1rem;
      color: #3B70F8;
      margin-bottom: 2.1vh;
    }

    &__title {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.125;
      color: #363636;
      text-transform: uppercase;

      &--link {
        color: #3B70F8;
      }
    }

    &__ending {
      margin-bottom: 2.5vh;
    }

    &__description, &__ending {
      font-size: 1rem;
      line-height: 1;

      &--link {
        font-weight: 700;
      }
    }
  }
`

export default StyledVariant;
