import React from 'react';

const TeamUsersIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="6.25" cy="6.25" rx="3.75" ry="4.375" fill="#262F56" />
    <path
      d="M11.875 16.875C11.875 18.75 10 18.125 6.24998 18.125C2.49995 18.125 0.625 18.75 0.625 16.875C0.625 15 2.49995 12.5 6.24998 12.5C10 12.5 11.875 15 11.875 16.875Z"
      // fill="#262F56"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 6.25C11.5 7.22921 11.2747 8.19119 10.8643 9.04413C11.5521 10.01 12.5895 10.625 13.75 10.625C15.8211 10.625 17.5 8.66625 17.5 6.25C17.5 3.83375 15.8211 1.875 13.75 1.875C12.5895 1.875 11.5521 2.49 10.8643 3.45587C11.2747 4.30881 11.5 5.27079 11.5 6.25ZM16 18.1845C18.25 18.2797 19.375 18.3274 19.375 16.875C19.375 15 17.5 12.5 13.75 12.5C12.8876 12.5 12.1243 12.6322 11.4603 12.8586C12.699 13.9769 13.375 15.4547 13.375 16.875C13.375 17.2809 13.3151 17.7187 13.1373 18.1303C13.3356 18.127 13.5399 18.125 13.75 18.125C14.5952 18.125 15.3452 18.1568 15.9999 18.1845H16H16ZM9.99999 18.224C11.25 18.2067 11.875 17.9575 11.875 16.875C11.875 15.7925 11.25 14.5016 9.99998 13.6038C8.74999 14.5016 8.125 15.7925 8.125 16.875C8.125 17.9575 8.74999 18.2067 9.99999 18.224Z"
      // fill="#262F56"
    />
  </svg>
);

export default TeamUsersIcon;
