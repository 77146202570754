import { createSlice } from '@reduxjs/toolkit';

import {
  CategoryType,
  CountryType,
  HistoryType,
  CandidateType,
} from 'utils/types';

const getInitialStore = () => ({
  candidatesList: [] as CandidateType[],
  candidate: null as unknown as CandidateType,
  historyList: [] as HistoryType[],
  count: 0,
  sortBy: 'createdAt',
  sortDirection: 'reverse',
  sortSelect: { value: ['createdAt', 'reverse'], label: 'latest' },
  paginationPerPage: 20,
  paginationPage: 1,
  hasMore: true,
  filter: {
    country: null as unknown as CountryType,
    skills: null as unknown as CategoryType,
    specializations: null as unknown as CategoryType,
    search: '',
  },
});

const initialStore = getInitialStore();

export type StoreType = typeof initialStore;

export const sliceName = 'candidatesList';

const candidatesListSlice = createSlice({
  name: sliceName,
  initialState: getInitialStore(),
  reducers: {
    setCandidatesList: (store, { payload }) => ({
      ...store,
      candidatesList: [
        ...(store.candidatesList as CandidateType[]),
        ...payload.candidates,
      ],
      count: payload.count,
    }),
    setCandidate: (store, { payload }) => ({
      ...store,
      candidate: payload,
    }),
    updateOneCandidate: (store, { payload }) => ({
      ...store,
      candidatesList: store.candidatesList!.map((candidate: CandidateType) =>
        candidate!.id === payload.id
          ? { ...candidate, ...(payload as CandidateType) }
          : candidate,
      ),
      candidate:
        store.candidate !== null && store.candidate?.id === payload.id
          ? { ...store.candidate, ...payload }
          : store.candidate,
    }),
    updateSearch: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        search: payload,
      },
    }),
    updateSort: (store, { payload: { value, label } }) => ({
      ...store,
      sortBy: value[0],
      sortDirection: value[1],
      sortSelect: {
        value,
        label,
      },
    }),
    updateFilter: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        [payload.name]: payload.value,
      },
    }),
    updatePage: (store) => ({
      ...store,
      paginationPage: store.paginationPage + 1,
    }),
    resetPage: (store) => ({
      ...store,
      paginationPage: 1,
    }),
    clearFilter: (store) => ({
      ...store,
      filter: getInitialStore().filter,
    }),
    resetCandidatesList: (store) => ({
      ...store,
      candidatesList: [],
      count: 0,
    }),
    isHasMore: (store, { payload }) => ({
      ...store,
      hasMore: payload >= store.paginationPerPage,
    }),
    setHistoryList: (store, { payload }) => ({
      ...store,
      historyList: [...payload],
    }),
    addRecordToHistoryList: (store, { payload }) => ({
      ...store,
      historyList: [payload, ...store.historyList],
    }),
  },
});

export const {
  setCandidatesList,
  setCandidate,
  resetCandidatesList,
  updateSearch,
  updateSort,
  updateFilter,
  clearFilter,
  updatePage,
  resetPage,
  isHasMore,
  updateOneCandidate,
  setHistoryList,
  addRecordToHistoryList,
} = candidatesListSlice.actions;

export default candidatesListSlice.reducer;
