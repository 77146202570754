import styled from 'styled-components';
import { Paper, Popper } from '@material-ui/core';

export const StyledPaper = styled(Paper)`
  && {
    width: 510px;
    overflow: hidden;
    max-height: 70vh;
    margin-top: 20px;
    font-weight: ${({ theme }) => theme.font.weight.md};
    font-family: ${({theme}) => theme.font.family.main};
    line-height: 28px;
    background: ${({ theme }) => theme.colors.pageBackground};
    color: ${({ theme }) => theme.colors.secondaryText};
    border: ${({ theme }) => theme.colors.mainBorder};
    border-radius: 5px;
  }

  .inner {
    &-header,
    &-footer {
      padding: 5px 21px 5px 15px;
      display: flex;
      justify-content: space-between;
    }

    &-body {
      max-height: 50vh;
      height: 100%;
      overflow: scroll;

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }

    &-header {
      align-items: center;
      margin-bottom: 5px;

      &-notifications {
        font-weight: ${({ theme }) => theme.font.weight.lg};
      }

      &-settings {
        display: flex;
        /* temp hide this */

        visibility: hidden;
        align-items: center;

        svg {
          padding-right: 7px;
        }
      }
    }

    &-footer {
      border-top: ${({ theme }) => theme.colors.mainBorder};
      &-see-all {
        visibility: hidden;
      }
    }
  }
`;

export const StyledPopper = styled(Popper)`
  /* sticky header has 20 */
  z-index: 21;
`;

const notificationsModalStyles = {
  StyledPaper,
  StyledPopper,
};

export default notificationsModalStyles;
