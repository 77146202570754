const countriesEn = {
  Remote: 'Remote',
  'Ukraine or Remote': 'Ukraine or Remote',
  Russia: 'Russia',
  Ukraine: 'Ukraine',
  Belarus: 'Belarus',
  Poland: 'Poland',
  Armenia: 'Armenia',
  China: 'China',
  Cyprus: 'Cyprus',
  Kyrgyzstan: 'Kyrgyzstan',
  Latvia: 'Latvia',
  Philippines: 'Philippines',
  Taiwan: 'Taiwan',
};

export default countriesEn;
