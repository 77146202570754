/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useAppSelector } from 'utils/hooks';
import { getInitials, logOut } from 'utils/helpers';
import StyledNavLink from './StyledNavLink';

const UserMenu: React.FC = () => {
  const { t } = useTranslation();
  const user = useAppSelector(({ main }) => main.user);
  const [anchor, setAnchor] = useState<Element | null>(null);

  const toggleAnchor = (event: React.MouseEvent) => {
    if (anchor) {
      return setAnchor(null);
    }

    return setAnchor(event.currentTarget);
  };

  const onLogOutClick = useCallback(() => {
    setAnchor(null);
    logOut();
  }, []);

  const initials = useMemo(() => getInitials(user && user.fullName), [user]);
  const anchorOrigin: AnchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  };
  const transformOrigin: TransformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  if (!user) return null;

  return (
    <>
      <StyledAvatar onClick={toggleAnchor}>{initials}</StyledAvatar>
      <Menu
        onClose={toggleAnchor}
        open={Boolean(anchor)}
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <StyledNavLink to="/my-account" activeClassName="current-link" exact>
          <MenuItem onClick={toggleAnchor}>{t('your account')}</MenuItem>
        </StyledNavLink>
        <MenuItem onClick={onLogOutClick}>{t('sign out')}</MenuItem>
      </Menu>
    </>
  );
};

export default memo(UserMenu);

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;

type AnchorOrigin = {
  vertical: 'bottom';
  horizontal: 'center';
};

type TransformOrigin = {
  vertical: 'top';
  horizontal: 'center';
};
