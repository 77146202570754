import React, { useState, useMemo } from 'react';

import config from 'config';
import { Menu, Typography } from '@material-ui/core';

import { useAppSelector, useAppDispatch } from 'utils/hooks';
import { getInitials } from 'utils/helpers';
import { changeCurrentCompany } from 'store/main/reducer';
import { EmployeesType } from 'utils/types';
import StyledAvatar from '../StyledAvatar';
import StyledItem from './style';

const CompanyChooser = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ main }) => main.user);
  const company = useAppSelector(({ main }) => main.company);
  const [anchor, setAnchor] = useState<Element | null>(null);

  const toggleAnchor = (event: React.MouseEvent) => {
    if (anchor) {
      return setAnchor(null);
    }

    return setAnchor(event.currentTarget);
  };

  const onChangeCompany = (event: React.MouseEvent, company: EmployeesType) => {
    dispatch(changeCurrentCompany(company));
    toggleAnchor(event);
  };

  const initials = useMemo(
    () => getInitials(company.current?.company?.name || ''),
    [company],
  );
  const anchorOrigin: AnchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  };
  const transformOrigin: TransformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  if (!user) return null;

  return (
    <>
      <StyledAvatar
        src={`${config.baseUrl}/${company.current?.company?.logoUrl}` || ''}
        onClick={toggleAnchor}
      >
        {initials}
      </StyledAvatar>
      <Menu
        onClose={toggleAnchor}
        open={Boolean(anchor)}
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {company.list.map((item) => (
          <StyledItem onClick={(event) => onChangeCompany(event, item)}>
            {item.company?.logoUrl && (
              <StyledAvatar
                alt="company-logo"
                src={`${config.baseUrl}/${item.company.logoUrl}`}
              >
                {getInitials(item.company?.name || '')}
              </StyledAvatar>
            )}
            <Typography>{item.company?.name}</Typography>
          </StyledItem>
        ))}
      </Menu>
    </>
  );
};

export default CompanyChooser;

type AnchorOrigin = {
  vertical: 'bottom';
  horizontal: 'center';
};

type TransformOrigin = {
  vertical: 'top';
  horizontal: 'center';
};
