import { createAsyncThunk } from '@reduxjs/toolkit';
import authApi from 'api/authApi';
import employeeApi from 'api/employeeApi';

import { updateUser, fillCompany } from './reducer';

const authorize = createAsyncThunk(
  'main/authorizeUser',
  async (arg, { dispatch }) => {
    try {
      const user = await authApi.check() as any;
      if (user.isSubclient) {
        const companies = await employeeApi.getListOfEmployeeCompanies(user.id);
        dispatch(fillCompany(companies));
      };

      dispatch(updateUser(user));
    } catch (err) {
      dispatch(updateUser(null));
    }
  },
);

export default authorize;
