import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCommentDots, faBriefcase } from '@fortawesome/free-solid-svg-icons';

const NotificationTypeIconObj = {
  candidate: faUser,
  comment: faCommentDots,
  vacancy: faBriefcase,
  client: faBriefcase,
};

const NotificationTypeIcon: React.FC<{ type: string }> = ({ type }) => (
  <FontAwesomeIcon icon={NotificationTypeIconObj[type]} size="lg" />
);

export default NotificationTypeIcon;
