import axios from 'api/axios';

const path = '/crm-notifications';

export const getList = (id: number): Promise<any> => axios.get(`${path}/${id}`);

export const markAboutCandidateAsRead = (id: number, userId: number): Promise<any> =>
  axios.patch(`${path}/read-about-candidate/${id}/${userId}`);

export const markAboutVacancyAsRead = (id: number, userId: number): Promise<any> =>
  axios.patch(`${path}/read-about-vacancy/${id}/${userId}`);

export const markAboutCompanyAsRead = (id: number, userId: number): Promise<any> =>
  axios.patch(`${path}/read-about-company/${id}/${userId}`);

export const markAsRead = (id: number): Promise<void> =>
  axios.patch(`${path}/read-all`, { id });

const crmNotificationsApi = {
  getList,
  markAsRead,
  markAboutCandidateAsRead,
  markAboutVacancyAsRead,
  markAboutCompanyAsRead,
};

export default crmNotificationsApi;
