import React, { memo, lazy } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';

import LazyWrapper from 'utils/HOC/LazyWrapper';
import protector from 'utils/HOC/protector';
import { useAppSelector } from 'utils/hooks';
import MainPageContent from 'ui/components/MainPageContent';
import { routePaths } from 'utils/constants';
import config from 'config';
import { UserRoleEnum } from 'utils/types.d';
import CustomRoute from './components/CustomRoute';

const Home = LazyWrapper(lazy(() => import('pages/Home')));
const Vacancy = LazyWrapper(lazy(() => import('pages/Vacancy')));
const CreateVacancy = LazyWrapper(
  lazy(() => import('pages/Vacancy/components/CreateAndEdit/CreateWrap')),
);
const VacancyDetails = LazyWrapper(
  lazy(() => import('pages/Vacancy/components/VacancyDetails/VacancyDetails')),
);
const Profile = LazyWrapper(lazy(() => import('pages/Profile')));
const Companies = LazyWrapper(lazy(() => import('pages/Companies')));

const CreateCompany = LazyWrapper(
  lazy(() => import('pages/Companies/components/CreateAndEdit/CreateWrap')),
);

const CreateCandidate = LazyWrapper(
  lazy(
    () =>
      import(
        'pages/Candidates/components/CreateAndEditCandidate/CreateAndEditCandidateWrap'
      ),
  ),
);

const companyDetails = LazyWrapper(
  lazy(
    () => import('pages/Companies/components/CompanyDetails/CompanyDetails'),
  ),
);

const CandidateDetails = LazyWrapper(
  lazy(() => import('pages/Candidates/components/CandidateDetails')),
);

const Candidates = LazyWrapper(lazy(() => import('pages/Candidates')));
const Recruiters = LazyWrapper(lazy(() => import('pages/Recruiters')));

const RecruiterDetails = LazyWrapper(
  lazy(() => import('pages/Recruiters/components/RecruiterDetails')),
);

const CreateRecruiter = LazyWrapper(
  lazy(
    () =>
      import(
        'pages/Recruiters/components/CreateAndEdit/CreateAndEditRecruiterWrap'
      ),
  ),
);

const HrDirectors = LazyWrapper(lazy(() => import('pages/HrDirectors')));

const HrDirectorDetails = LazyWrapper(
  lazy(() => import('pages/HrDirectors/components/HrDirectorDetails')),
);

const CreateHrDirector = LazyWrapper(
  lazy(
    () =>
      import(
        'pages/HrDirectors/components/CreateAndEdit/CreateAndEditHrDirectorsWrap'
      ),
  ),
);

const Managers = LazyWrapper(lazy(() => import('pages/Managers')));

const ManagerDetails = LazyWrapper(
  lazy(() => import('pages/Managers/components/ManagerDetails')),
);

const CreateManager = LazyWrapper(
  lazy(() => import('pages/Managers/components/CreateAndEdit')),
);

const Teams = LazyWrapper(lazy(() => import('pages/Teams')));

const TeamDetails = LazyWrapper(
  lazy(() => import('pages/Teams/components/TeamDetails')),
);

const Employees = LazyWrapper(lazy(() => import('pages/Employees')));

const CreateEmployee = LazyWrapper(
  lazy(() => import('pages/Employees/components/CreateAndEdit')),
);

const Router: React.FC = () => {
  const user = useAppSelector(({ main }) => main.user);
  if (!user) {
    return (
      // this is temp plug;
      <MainPageContent>
        <h1>You should authorize</h1>
        <br />
        <a href={config.baseUrl}> Go to main website </a>
      </MainPageContent>
    );
  }

  const routes = [
    {
      path: routePaths.home,
      component: Home,
    },
    {
      path: routePaths.vacancy.list,
      component: Vacancy,
    },
    {
      path: routePaths.vacancy.create,
      component: CreateVacancy,
      roles: [
        UserRoleEnum.HRD,
        UserRoleEnum.Manager,
        UserRoleEnum.Admin,
        UserRoleEnum.Client,
      ],
    },
    {
      path: routePaths.vacancy.edit,
      component: CreateVacancy,
      roles: [
        UserRoleEnum.HRD,
        UserRoleEnum.Manager,
        UserRoleEnum.Admin,
        UserRoleEnum.Client,
      ],
    },
    {
      path: routePaths.vacancy.one,
      component: VacancyDetails,
    },
    {
      path: routePaths.profile,
      component: Profile,
    },
    {
      path: routePaths.company.list,
      component: Companies,
      roles: [
        UserRoleEnum.HRD,
        UserRoleEnum.Admin,
        UserRoleEnum.Manager,
        UserRoleEnum.Admin,
      ],
    },
    {
      path: routePaths.company.create,
      component: CreateCompany,
      roles: [UserRoleEnum.HRD, UserRoleEnum.Admin, UserRoleEnum.Admin],
    },
    {
      path: routePaths.company.edit,
      component: CreateCompany,
      roles: [UserRoleEnum.HRD, UserRoleEnum.Admin, UserRoleEnum.Manager, UserRoleEnum.Admin],
    },
    {
      path: routePaths.company.one,
      component: companyDetails,
      roles: [
        UserRoleEnum.HRD,
        UserRoleEnum.Admin,
        UserRoleEnum.Manager,
        UserRoleEnum.Admin,
      ],
    },
    {
      path: routePaths.company.staffList,
      component: Employees,
      roles: [UserRoleEnum.Admin, UserRoleEnum.HRD, UserRoleEnum.Manager],
    },
    {
      path: routePaths.company.staffCreate,
      component: CreateEmployee,
      roles: [UserRoleEnum.Admin, UserRoleEnum.HRD, UserRoleEnum.Manager],
    },

    {
      path: routePaths.candidate.list,
      component: Candidates,
      roles: [
        UserRoleEnum.HRD,
        UserRoleEnum.Manager,
        UserRoleEnum.Recruiter,
        UserRoleEnum.Admin,
        UserRoleEnum.Client,
      ],
    },
    {
      path: routePaths.candidate.create,
      component: CreateCandidate,
      roles: [
        UserRoleEnum.HRD,
        UserRoleEnum.Manager,
        UserRoleEnum.Recruiter,
        UserRoleEnum.Admin,
      ],
    },
    {
      path: routePaths.candidate.edit,
      component: CreateCandidate,
      roles: [
        UserRoleEnum.HRD,
        UserRoleEnum.Manager,
        UserRoleEnum.Recruiter,
        UserRoleEnum.Admin,
      ],
    },
    {
      path: routePaths.candidate.one,
      component: CandidateDetails,
      roles: [
        UserRoleEnum.HRD,
        UserRoleEnum.Manager,
        UserRoleEnum.Recruiter,
        UserRoleEnum.Admin,
        UserRoleEnum.Client,
      ],
    },
    {
      path: routePaths.recruiter.list,
      component: Recruiters,
      roles: [UserRoleEnum.HRD, UserRoleEnum.Admin, UserRoleEnum.Manager],
    },
    {
      path: routePaths.recruiter.create,
      component: CreateRecruiter,
      roles: [UserRoleEnum.HRD, UserRoleEnum.Admin, UserRoleEnum.Manager],
    },
    {
      path: routePaths.recruiter.edit,
      component: CreateRecruiter,
      roles: [UserRoleEnum.HRD, UserRoleEnum.Admin, UserRoleEnum.Manager],
    },
    {
      path: routePaths.recruiter.one,
      component: RecruiterDetails,
      roles: [UserRoleEnum.HRD, UserRoleEnum.Admin, UserRoleEnum.Manager],
    },
    {
      path: routePaths.hrDirector.list,
      component: HrDirectors,
      roles: [UserRoleEnum.Admin],
    },
    {
      path: routePaths.hrDirector.create,
      component: CreateHrDirector,
      roles: [UserRoleEnum.Admin],
    },
    {
      path: routePaths.hrDirector.edit,
      component: CreateHrDirector,
      roles: [UserRoleEnum.Admin],
    },
    {
      path: routePaths.hrDirector.one,
      component: HrDirectorDetails,
      roles: [UserRoleEnum.Admin],
    },
    {
      path: routePaths.manager.list,
      component: Managers,
      roles: [UserRoleEnum.Admin, UserRoleEnum.HRD],
    },
    {
      path: routePaths.manager.create,
      component: CreateManager,
      roles: [UserRoleEnum.Admin, UserRoleEnum.HRD],
    },
    {
      path: routePaths.manager.edit,
      component: CreateManager,
      roles: [UserRoleEnum.Admin, UserRoleEnum.HRD],
    },
    {
      path: routePaths.manager.one,
      component: ManagerDetails,
      roles: [UserRoleEnum.Admin, UserRoleEnum.HRD],
    },
    {
      path: routePaths.team.list,
      component: Teams,
      roles: [
        UserRoleEnum.Admin,
        UserRoleEnum.HRD,
        UserRoleEnum.Manager,
        UserRoleEnum.Recruiter,
      ],
    },
    {
      path: routePaths.team.one,
      component: TeamDetails,
      roles: [
        UserRoleEnum.HRD,
        UserRoleEnum.Admin,
        UserRoleEnum.Manager,
        UserRoleEnum.Recruiter,
      ],
    },
    {
      path: routePaths.staff.list,
      component: Employees,
      roles: [UserRoleEnum.Client],
    },
    {
      path: routePaths.staff.create,
      component: CreateEmployee,
      roles: [UserRoleEnum.Admin, UserRoleEnum.Client],
    },
  ];

  const protectedRoutes = routes.map((route: RouteType) => {
    let { component } = route;

    if (route.roles) {
      component = protector(component, route.roles);
    }

    return { ...route, component };
  });

  return (
    <Switch>
      <Redirect exact from="/" to={routePaths.vacancy.list} />
      {protectedRoutes.map((route, index) => (
        <CustomRoute key={index} {...route} exact={route.exact ?? true} />
      ))}
    </Switch>
  );
};

type RouteType = {
  path: string;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  roles?: string[] | string;
  exact?: boolean;
};

export default memo(Router);
