import React from 'react';

import { useAppSelector } from 'utils/hooks';

import StyledSidebar from './Sidebar.style';
import NavMenu from './components/NavMenu';
import RootLeftPadding from './components/RootLeftPadding';

const Sidebar: React.FC = () => {
  const { isSidebarOpen } = useAppSelector(({ main }) => main);

  return (
    <StyledSidebar isOpen={isSidebarOpen}>
      <NavMenu isOpen={isSidebarOpen} />
      <RootLeftPadding isOpen={isSidebarOpen} />
    </StyledSidebar>
  );
};

export default Sidebar;
