import React, { memo } from 'react';
import { ThemeProvider } from 'styled-components';

import themes from 'ui/styles/StyledComponentsTheme/themes';

import { useAppSelector } from 'utils/hooks/';
import { ChildrenType } from 'utils/types';

type PropType = {
  children: ChildrenType;
};

const StyledComponentsTheme: React.FC<PropType> = ({ children }) => {
  const theme = useAppSelector(({ main }) => main.theme);

  return <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>;
};

export default memo(StyledComponentsTheme);
