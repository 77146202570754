import { createSlice } from '@reduxjs/toolkit';

import { EmployeesType } from 'utils/types';

const getInitialStore = () => ({
  employeesList: [] as EmployeesType[],
  employee: null as unknown as EmployeesType,
  count: 0,
  sortBy: 'createdAt',
  sortDirection: 'reverse',
  sortSelect: { value: ['createdAt', 'reverse'], label: 'latest' },
  paginationPerPage: 20,
  paginationPage: 1,
  hasMore: true,
  filter: {
    search: '',
    // status: [{ value: 'active', label: 'active' }],
  },
});

const initialStore = getInitialStore();

export type StoreType = typeof initialStore;

export const sliceName = 'employeesList';

const employeesListSlice = createSlice({
  name: sliceName,
  initialState: getInitialStore(),
  reducers: {
    setEmployeesList: (
      store,
      { payload }: { payload: { employees: EmployeesType[]; count: number } },
    ) => ({
      ...store,
      employeesList: [
        ...payload.employees,
      ],
      count: payload.count,
    }),
    addEmployeeToList: (
      store,
      { payload }: { payload: { employee: EmployeesType; count: number } },
    ) => ({
      ...store,
      employeesList: [payload.employee, ...store.employeesList],
      count: payload.count,
    }),
    setEmployee: (store, { payload }) => ({
      ...store,
      employee: payload,
    }),
    updateOneEmployee: (store, { payload }) => ({
      ...store,
      employeesList: store.employeesList!.map((employee: EmployeesType) =>
        employee!.id === payload.id
          ? { ...employee, ...(payload as EmployeesType) }
          : employee,
      ),
      employee:
        store.employee !== null && store.employee?.id === payload.id
          ? { ...store.employee, ...payload }
          : store.employee,
    }),
    updateSearch: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        search: payload,
      },
    }),
    updateSort: (store, { payload: { value, label } }) => ({
      ...store,
      sortBy: value[0],
      sortDirection: value[1],
      sortSelect: {
        value,
        label,
      },
    }),
    updateFilter: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        [payload.name]: payload.value,
      },
    }),
    updatePage: (store) => ({
      ...store,
      paginationPage: store.paginationPage + 1,
    }),
    resetPage: (store) => ({
      ...store,
      paginationPage: 1,
    }),
    clearFilter: (store) => ({
      ...store,
      filter: getInitialStore().filter,
    }),
    resetEmployeesList: (store) => ({
      ...store,
      employeesList: [],
      count: 0,
    }),
    isHasMore: (store, { payload }) => ({
      ...store,
      hasMore: payload >= store.paginationPerPage,
    }),
  },
});

export const {
  setEmployeesList,
  setEmployee,
  resetEmployeesList,
  updateSearch,
  updateSort,
  updateFilter,
  clearFilter,
  updatePage,
  resetPage,
  isHasMore,
  updateOneEmployee,
  addEmployeeToList,
} = employeesListSlice.actions;

export default employeesListSlice.reducer;
