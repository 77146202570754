import axios from 'api/axios';
import {
  CompanyFormValues,
  CompanyType,
  Discount,
  FilterType,
  PaginationType,
  SortType,
} from 'utils/types';

const path = '/company';

type ParamsType = {
  sort?: SortType;
  pagination?: PaginationType;
  filter: FilterType;
};

export const getList = (
  params: ParamsType,
): Promise<{ data: CompanyType[]; count: number }> =>
  axios.get(path, {
    params: {
      ...params.sort,
      ...params.pagination,
      filter: {
        status: params.filter.status,
        manager: params.filter.manager,
        search: params.filter.search,
      },
    },
  });

export const create = (data: CompanyFormValues): Promise<CompanyType> => {
  const formData = new FormData();

  const { selectedImages: uploadingImages, messengers, contract } = data;

  // eslint-disable-next-line guard-for-in
  for (const imageCategory in uploadingImages) {
    if (imageCategory === 'logoUrl') {
      formData.append(
        imageCategory,
        uploadingImages[imageCategory] as string | Blob,
      );
      // eslint-disable-next-line no-continue
      continue;
    }
    uploadingImages[imageCategory].forEach((image: string | Blob) =>
      formData.append(imageCategory, image),
    );
  }

  for (const messenger of messengers) {
    JSON.stringify(messenger);
  }

  const { selectedImages, ...rest } = data;

  const payload = JSON.stringify(rest);

  formData.append('data', payload);
  formData.append('contract', contract as string);

  return axios.post(path, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const update = (
  id: number,
  data: CompanyFormValues,
): Promise<CompanyType> => {
  const formData = new FormData();

  const { selectedImages: uploadingImages, messengers, contract } = data;

  for (const imageCategory in uploadingImages) {
    if (!uploadingImages[imageCategory]) continue;
    if (imageCategory === 'logoUrl') {
      formData.append(
        imageCategory,
        uploadingImages[imageCategory] as string | Blob,
      );
      continue;
    }
    uploadingImages[imageCategory].forEach((image: string | Blob) =>
      formData.append(imageCategory, image),
    );
  }

  const { selectedImages, ...rest } = data;

  for (const messenger of messengers) {
    JSON.stringify(messenger);
  }

  const payload = JSON.stringify(rest);

  formData.append('data', payload);
  formData.append('contract', contract as string);

  return axios.patch(`${path}/update/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteOne = (id: number): Promise<CompanyType> =>
  axios.delete(`${path}/${id}`);

export const restoreOne = (id: number): Promise<CompanyType> =>
  axios.put(`${path}/${id}`); 

export const getOne = (id: number): Promise<CompanyType> =>
  axios.get(`${path}/${id}`);

export const updateDiscount = (id: number): Promise<{ data: Discount }> =>
  axios.patch(`${path}/${id}/update-discount`, {});

export const getDiscount = (id: number): Promise<{ data: Discount }> =>
  axios.get(`${path}/${id}/discount`);

export const closeDiscount = (data: { id: number, userId: number }): Promise<void> =>
  axios.delete(`${path}/${data.id}/discount/${data.userId}`);

export const checkDiscount = (): Promise<void> => axios.get(`${path}/check-discount`);

const companyApi = {
  getList,
  getOne,
  update,
  create,
  deleteOne,
  restoreOne,
  updateDiscount,
  getDiscount,
  closeDiscount,
  checkDiscount,
};

export default companyApi;
