import { createSlice } from '@reduxjs/toolkit';

import { SelectType, RecruiterType } from 'utils/types';

const getInitialStore = () => ({
  recruitersList: [] as RecruiterType[],
  recruiter: null as unknown as RecruiterType,
  count: 0,
  sortBy: 'createdAt',
  sortDirection: 'reverse',
  sortSelect: { value: ['createdAt', 'reverse'], label: 'latest' },
  paginationPerPage: 20,
  paginationPage: 1,
  hasMore: true,
  filter: {
    manager: null as unknown as SelectType,
    team: null as unknown as SelectType,
    search: '',
    status: [{ value: 'active', label: 'active' }],
  },
});

const initialStore = getInitialStore();

export type StoreType = typeof initialStore;

export const sliceName = 'recruitersList';

const recruitersListSlice = createSlice({
  name: sliceName,
  initialState: getInitialStore(),
  reducers: {
    setRecruitersList: (
      store,
      { payload }: { payload: { recruiters: RecruiterType[]; count: number } },
    ) => ({
      ...store,
      recruitersList: [
        ...(store.recruitersList as RecruiterType[]),
        ...payload.recruiters,
      ],
      count: payload.count,
    }),
    addRecruiterToList: (
      store,
      { payload }: { payload: { recruiter: RecruiterType; count: number } },
    ) => ({
      ...store,
      recruitersList: [payload.recruiter, ...store.recruitersList],
      count: payload.count,
    }),
    setRecruiter: (store, { payload }) => ({
      ...store,
      recruiter: payload,
    }),
    updateOneRecruiter: (store, { payload }) => ({
      ...store,
      recruitersList: store.recruitersList!.map((recruiter: RecruiterType) =>
        recruiter!.id === payload.id
          ? { ...recruiter, ...(payload as RecruiterType) }
          : recruiter,
      ),
      recruiter:
        store.recruiter !== null && store.recruiter?.id === payload.id
          ? { ...store.recruiter, ...payload }
          : store.recruiter,
    }),
    updateSearch: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        search: payload,
      },
    }),
    updateSort: (store, { payload: { value, label } }) => ({
      ...store,
      sortBy: value[0],
      sortDirection: value[1],
      sortSelect: {
        value,
        label,
      },
    }),
    updateFilter: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        [payload.name]: payload.value,
      },
    }),
    updatePage: (store) => ({
      ...store,
      paginationPage: store.paginationPage + 1,
    }),
    resetPage: (store) => ({
      ...store,
      paginationPage: 1,
    }),
    clearFilter: (store) => ({
      ...store,
      filter: getInitialStore().filter,
    }),
    resetRecruitersList: (store) => ({
      ...store,
      recruitersList: [],
      count: 0,
    }),
    isHasMore: (store, { payload }) => ({
      ...store,
      hasMore: payload >= store.paginationPerPage,
    }),
    setHistoryList: (store, { payload }) => ({
      ...store,
      historyList: [...payload],
    }),
  },
});

export const {
  setRecruitersList,
  setRecruiter,
  resetRecruitersList,
  updateSearch,
  updateSort,
  updateFilter,
  clearFilter,
  updatePage,
  resetPage,
  isHasMore,
  updateOneRecruiter,
  setHistoryList,
  addRecruiterToList,
} = recruitersListSlice.actions;

export default recruitersListSlice.reducer;
