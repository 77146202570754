import i18next from 'i18next';

import { add, format, parseISO, addMonths } from 'date-fns';
import { ru, enUS } from 'date-fns/locale';

import { setAuthorizationToken } from 'api/axios';
import _ from 'lodash';

import { resetStore } from 'store/rootReducer';
import store from 'store';
import config from 'config';
import theme from 'ui/styles/StyledComponentsTheme/themes/main';
import token from './token';
import {
  CandidateType,
  CurrencyType,
  SelectType,
  StringOrNull,
  UserRoleEnum,
  UserTypeCommonFields,
} from './types.d';
import { googleCalendarDateFormat, googleCalendarLink } from './constants';

export const isAdminOrHRDirector = (role: UserRoleEnum) => {
  const allowedRoles = [UserRoleEnum.Admin, UserRoleEnum.HRD];
  return allowedRoles.includes(role);
};

export const getInitials = (fullName: string) => {
  if (!fullName) return null;

  const firstName = fullName.split(' ')[0];
  const lastName = fullName.split(' ')[1];

  if (!lastName) {
    return firstName.substring(0, 1);
  }

  const initials = `${lastName.substring(0, 1)}${firstName.substring(0, 1)}`;

  return initials;
};

export const logOut = () => {
  store.dispatch(resetStore());
  token.access.set(null);
  token.refresh.set(null);
  setAuthorizationToken();
  window.location.replace(config.baseUrl);
};

type ItemForFormat = {
  name?: string;
  title?: string;
  id: number;
};

type ReturnType = {
  label: string;
  value: number;
};

export const formatArrayToSelectOptions = (
  data: ItemForFormat[],
): ReturnType[] | null => {
  if (!data) return null;

  const formattedData = data.map(({ name, title, id }: ItemForFormat) => ({
    label: name ? i18next.t(name as string) : i18next.t(title as string) || '',
    value: id,
  }));

  return formattedData;
};

export const formatArrayOfCountriesToSelectOptions = (
  data: FormatItem[],
): ReturnType[] | null => {
  if (!data) return null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formattedData = data.map(({ name, name_en, id }: FormatItem) => ({
    label: i18next.t(name_en),
    value: id,
  }));

  return formattedData;
};

export const formatUserToSelectOptions = (
  users: {
    id: UserTypeCommonFields['id'];
    fullName: UserTypeCommonFields['fullName'];
  }[],
) => {
  if (!users) return null;

  const formattedUsers = users.map((user) => ({
    label: user.fullName,
    value: user.id,
  }));

  return formattedUsers;
};

export const formatArrayOfCandidatesCountries = (data: any, lang: string) => {
  if (!data) return null;

  const formattedData = data.map((item: any) => ({
    label: lang === 'ru' ? item.title_ru : item.title_en,
    value: item.country_id,
  }));

  return formattedData;
};

type FormatItem = {
  name_en: string;
  name: string;
  id: number;
};

export const formatObjToSelectOptions = (data: ItemForFormat | undefined) => {
  if (!data) return null;

  const formattedData = {
    label: data.name ? i18next.t(data.name) : i18next.t(data.title as string),
    value: data.id,
  };

  return formattedData;
};

export const formatCountryToSelectOptions = (data: FormatItem | undefined) => {
  if (!data) return null;

  const formattedData = {
    label: i18next.t(data.name_en),
    value: data.id,
  };

  return formattedData;
};

export const getCreationDate = (
  date: Date | string,
  lang = 'ru',
  withTime = false,
  withYear = false,
) => {
  if (!date) return '';
  if (withYear) {
    return format(new Date(date), 'd MMMM yyyy, hh:mm', {
      locale: lang === 'ru' ? ru : enUS,
    });
  }

  const toFormat = withTime ? 'd MMMM, HH:mm' : 'd MMMM';

  return format(new Date(date), toFormat, {
    locale: lang === 'ru' ? ru : enUS,
  });
};

export const addPublicationTime = (
  date: string,
  period: string,
  amount: number,
) =>
  add(new Date(date), {
    [period]: amount,
  });

export const getPublicationExpirationDate = (
  createdAt: string,
  publicationPeriod: string,
  lang = 'ru',
) => {
  switch (publicationPeriod) {
    case 'OneWeek': {
      const period = 'weeks';
      const amount = 1;
      const futureDate = addPublicationTime(createdAt, period, amount);
      const date = getCreationDate(futureDate, lang);
      return date;
    }
    case 'Month': {
      const period = 'months';
      const amount = 1;
      const futureDate = addPublicationTime(createdAt, period, amount);
      const date = getCreationDate(futureDate, lang);
      return date;
    }
    case 'TwoMonths': {
      const period = 'months';
      const amount = 2;
      const futureDate = addPublicationTime(createdAt, period, amount);
      const date = getCreationDate(futureDate, lang);
      return date;
    }
    case 'ThreeMonths': {
      const period = 'months';
      const amount = 3;
      const futureDate = addPublicationTime(createdAt, period, amount);
      const date = getCreationDate(futureDate, lang);
      return date;
    }

    case 'Year': {
      const period = 'years';
      const amount = 1;
      const futureDate = addPublicationTime(createdAt, period, amount);
      const date = getCreationDate(futureDate, lang);
      return date;
    }

    default:
      return undefined;
  }
};

export const formatToPreviewLink = (images: any) => {
  if (!images) return [];

  if (
    (typeof images === 'object' && !Array.isArray(images)) ||
    typeof images === 'string'
  ) {
    return {
      name: images,
      previewLink: `${config.baseUrl}/${images}`,
    };
  }

  return images.map((item: string) => ({
    name: item,
    previewLink: `${config.baseUrl}/${item}`,
  }));
};

export const getPercentagesFromPayment = (payment: StringOrNull) => {
  if (!payment) return null;

  const numberReg = /\d+/g;

  return payment.match(numberReg)?.[0];
};

export const formatCompanyWebsite = (url: string) => {
  const regEx = /https?:\/\//g;

  if (!url) return null;

  const trimHttp = url.replace(regEx, '');

  return trimHttp;
};

export const formatUserSkillsToSelect = (skills: any) => {
  if (!skills) return null;

  const arrayOfSkills = skills.split('/');

  if (arrayOfSkills[arrayOfSkills.length - 1] === ' ') {
    arrayOfSkills.splice(-1, 1);
  }

  const formattedSkills = arrayOfSkills.map((item: string) => ({
    label: item.trim(),
    value: item,
  }));

  return formattedSkills;
};

export const isHttpUrl = (cvLink: string) => {
  if (!cvLink) return null;

  const regEx = /https?:\/\//g;

  return regEx.test(cvLink);
};

export const getCommentByAction = (actionType: string, actionValue: string) => {
  if (actionType === 'status') {
    return `status was changed to ${actionValue}`;
  }
  return `candidate was sended to ${actionValue}`;
};

export const getAvatar = (
  {
    avatarThumbnail,
    avatar,
  }: {
    avatar: StringOrNull;
    avatarThumbnail: StringOrNull;
  } = { avatar: '', avatarThumbnail: '' },
): string => {
  if (!avatarThumbnail && !avatar) return '';
  if (avatarThumbnail) return `${config.baseUrl}${avatarThumbnail}`;
  return `${config.baseUrl}${avatar}`;
};

// TODO generic
export const getClosedPositionCost = (user: any) => {
  if (!user) return null;

  const { closedPositionCost } = user;

  const formattedClosedPositionCost = closedPositionCost || 0;

  return formattedClosedPositionCost;
};

export const getFileNameFromPath = (path: string) => {
  if (!path) return null;

  const name = path.split('/')[2].split('-')[0];

  return name;
};

export const getTranslatedStatus = <T extends Function>(
  t: T,
  statuses: SelectType[] | null,
) => {
  const result = statuses?.map((status: SelectType) => ({
    label: t(status?.label),
    value: status.value,
  }));

  return result;
};

export const checkIsMyCandidate = (
  recruiterId: number,
  userRole: UserRoleEnum,
  userId: number,
) => (userRole !== UserRoleEnum.Recruiter ? true : recruiterId === userId);

export const sortCandidatesByStatus = <T extends Record<string, any>>(
  ownCandidates: T[],
  notificationsIdsList?: number[],
): T[] => {
  const ownCandidatesForSort = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ownCandidates.length; i++) {
    const currentCandidate = ownCandidates[i];
    const currentCandidateStatus = String(
      // eslint-disable-next-line
      currentCandidate.hasOwnProperty('candidateStatus')
        ? currentCandidate.candidateStatus
        : currentCandidate.placeToWork[0]?.status,
    ).toLocaleLowerCase();

    if (currentCandidateStatus === 'inprogress') {
      ownCandidatesForSort.push({
        ...currentCandidate,
        priority: notificationsIdsList?.includes(currentCandidate.id) ? 1 : 2,
      });
    } else if (currentCandidateStatus === 'rejected') {
      ownCandidatesForSort.push({
        ...currentCandidate,
        priority: notificationsIdsList?.includes(currentCandidate.id) ? 1 : 6,
      });
    } else if (currentCandidateStatus === 'hold') {
      ownCandidatesForSort.push({
        ...currentCandidate,
        priority: notificationsIdsList?.includes(currentCandidate.id) ? 1 : 3,
      });
    } else if (currentCandidateStatus === 'preoffer') {
      ownCandidatesForSort.push({
        ...currentCandidate,
        priority: notificationsIdsList?.includes(currentCandidate.id) ? 1 : 4,
      });
    } else if (currentCandidateStatus === 'offer') {
      ownCandidatesForSort.push({
        ...currentCandidate,
        priority: notificationsIdsList?.includes(currentCandidate.id) ? 1 : 5,
      });
    } else if (currentCandidateStatus === 'offerrefused') {
      ownCandidatesForSort.push({
        ...currentCandidate,
        priority: notificationsIdsList?.includes(currentCandidate.id) ? 1 : 7,
      });
    }
  }

  const sortedOwnCandidates = ownCandidatesForSort.sort((a, b) => {
    if (a.priority === b.priority) {
      return b.id - a.id;
    }

    return a.priority - b.priority;
  });

  return sortedOwnCandidates;
};

export const getSortedCandidates = (
  candidatesList: CandidateType[],
  notificationsIdsList: number[],
  id: number,
  userRole: UserRoleEnum,
) => {
  if (!candidatesList.length) return [];
  const ownCandidates: CandidateType[] = [];
  const restCandidates: CandidateType[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < candidatesList.length; i++) {
    const currentCandidate = candidatesList[i];

    if (
      userRole === UserRoleEnum.Recruiter &&
      currentCandidate.recruiter_id !== id
    ) {
      restCandidates.push(currentCandidate);
      continue;
    }

    ownCandidates.push(currentCandidate);
  }

  const sortedOwnCandidates = sortCandidatesByStatus(
    ownCandidates,
    notificationsIdsList,
  );

  const sortedRestCandidates = restCandidates.sort((c) =>
    notificationsIdsList.includes(c.id) ? -1 : 1,
  );

  const allCandidates = [...sortedOwnCandidates, ...sortedRestCandidates];

  return allCandidates;
};

const isWwwStarts = /(www.[^\s]+)/gm;
const isUrl = /(https:\/\/www.|http:\/\/www.|https:\/\/|http:\/\/)[^\s]+/gm;

export const converTextToUrl = (text: string) => {
  let transformed;

  if (isUrl.test(text)) {
    transformed = text.replace(
      isUrl,
      (el) =>
        `<a target="_blank" rel="noreferrer" class="activity-link" href="${el}">${el}</a>`,
    );
  } else if (isWwwStarts.test(text)) {
    transformed = text.replace(
      isWwwStarts,
      (el) =>
        `<a target="_blank" rel="noreferrer" class="activity-link" href="http://${el}">${el}</a>`,
    );
  } else {
    transformed = text;
  }

  return transformed;
};

export const getStatusColor = (status: string, isAdminMarked?: boolean) => {
  switch (status) {
    case 'InProgress':
    case 'In progress':
    case 'Active':
      return '#60C837';

    case 'Offer':
      return isAdminMarked ? theme.colors.goldenColor : theme.colors.offerColor;

    case 'OfferRefused':
    case 'Rejected':
    case 'Closed':
      return '#C85037';

    case 'Published':
      return '#37C8AB';

    case 'PreOffer':
      return theme.colors.preOfferColor;

    default:
      return '#9B9B9B';
  }
};

// @ts-ignore
export const readOwnNotifications = (data, id) => {
  if (Array.isArray(data)) {
    return data
      .filter((n) => +n.authorId === id)
      .map((n) => ({ ...n, isRead: true }));
  }

  if (data.authorId !== id) return data;

  return {
    ...data,
    isRead: true,
  };
};

export const getNotificationsByType = <T>(
  type: string,
  notificationsList: Array<T & { iconType: string }>,
) => {
  switch (type) {
    case 'vacancy': {
      return notificationsList.filter(
        (notification) => notification.iconType === 'vacancy',
      );
    }

    case 'candidate':
      return notificationsList.filter(
        (notification) => notification.iconType === 'candidate',
      );

    case 'comment':
      return notificationsList.filter(
        (notification) => notification.iconType === 'comment',
      );

    case 'client':
      return notificationsList.filter(
        (notification) => notification.iconType === 'client',
      );

    default:
      return notificationsList;
  }
};

export const getNotificationsByEntityId = (
  entityId: number,
  entityType: string,
  notifcations: {
    candidateId: string;
    vacancyId: string;
    clientId: string;
    iconType: string;
  }[],
) => {
  switch (entityType) {
    case 'vacancy': {
      return notifcations.filter(
        (notification) =>
          +notification.vacancyId === entityId &&
          notification.iconType === 'vacancy',
      );
    }
    case 'candidate': {
      return notifcations.filter(
        (notification) =>
          +notification.candidateId === entityId &&
          notification.iconType === 'candidate',
      );
    }
    case 'client': {
      return notifcations.filter(
        (notification) =>
          +notification.clientId === entityId &&
          notification.iconType === 'client',
      );
    }

    default:
      return notifcations;
  }
};

type NotificationArrayType = {
  type: string;
  iconType: string;
  vacancyId: string;
  isRead: boolean;
  candidateId: string;
};

export const checkIsShowCommentIcon = (
  notificationsArr: NotificationArrayType[],
  id: number,
  entityIdKey: string,
) =>
  getNotificationsByType('comment', notificationsArr).some(
    (notification) =>
      notification.type === 'Candidate_comment_added' &&
      +notification[entityIdKey] === id &&
      !notification.isRead,
  );

export const getUnreadedNotificationsCount = <T>(
  notifications: Array<T & { isRead: boolean }>,
) => notifications.filter((i) => !i.isRead);

const disableRurFromCurrencies = (currencies: CurrencyType[]) =>
  currencies.filter((curr) => curr.name !== 'RUR');

export const disableRurAndSortCurrenicesInCustomOrder = (
  currencies: CurrencyType[],
) => {
  const customCurrenciesOrder = {
    USD: 1,
    EUR: 2,
    CNY: 3,
    USDT: 4,
    UAH: 5,
  };

  const validCurrencies = disableRurFromCurrencies(currencies);

  const sortedCurrenices = validCurrencies.sort(
    (a, b) => customCurrenciesOrder[a.name] - customCurrenciesOrder[b.name],
  );

  return sortedCurrenices;
};

export const getDifferentKeys = (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
  path = '',
): string[] => {
  let differentKeys: string[] = [];

  // eslint-disable-next-line
  for (const key in obj1) {
    const newPath = path ? `${path}.${key}` : key;
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (_.isEqual(value1, value2)) {
      continue;
    }

    if (_.isObject(value1) && _.isObject(value2)) {
      differentKeys = differentKeys.concat(
        getDifferentKeys(value1, value2, newPath),
      );
    } else if (_.isArray(value1) && _.isArray(value2)) {
      if (!_.isEqual(value1, value2) || value1.length !== value2.length) {
        differentKeys.push(newPath);
      }
    } else {
      differentKeys.push(newPath);
    }
  }

  return differentKeys;
};

export const globalFilter = <T extends Record<string, unknown>>(
  data: T[],
  searchText: string,
): T[] => {
  const filteredData = data.filter((item) => {
    for (const key in item) {
      if (typeof item[key] === 'object' && item[key] !== null) {
        const nestedArray = Object.values(item[key] as Record<string, unknown>);
        const includesSearchText = nestedArray.some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase()),
        );
        if (includesSearchText) {
          return true;
        }
      } else if (
        String(item[key]).toLowerCase().includes(searchText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  return filteredData;
};

const parseDate = (date: Date | string) =>
  (typeof date === 'string' ? parseISO(date) : date) as Date;

const generateEventLink = ({
  summary,
  startDateTime,
  guests,
  description,
}: {
  summary: string;
  startDateTime: Date | string;
  guests: string[];
  description: string;
}): string => {
  const encodedSummary = encodeURIComponent(summary);
  const encodedGuests = guests
    .map((guest) => encodeURIComponent(guest))
    .join('&add=');
  const encodedDescription = encodeURIComponent(description);
  const parsedStart = parseDate(startDateTime);
  const formattedStartDateTime = format(parsedStart, googleCalendarDateFormat);

  const eventLink = `${googleCalendarLink}?action=TEMPLATE&text=${encodedSummary}&dates=${formattedStartDateTime}/${formattedStartDateTime}&add=${encodedGuests}&details=${encodedDescription}`;
  return eventLink;
};

const getFullMonthName = (monthNumber: number | undefined) => {
  const currentDate = new Date();
  const monthIndex = typeof monthNumber === 'number' ? monthNumber : currentDate.getMonth();
  const specificMonth = addMonths(new Date(0), monthIndex);
  
  const fullMonthName = format(specificMonth, 'MMMM');
  return fullMonthName;
}

const addHttps = (url: string) => {
  if (!url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
}

const generateLinkedInShareLink = (text: string): string => {
  const encodedText = encodeURIComponent(text);

  const shareLink = `https://www.linkedin.com/feed/?shareActive&mini=true&text=${encodedText}`;
  return shareLink;
};

const usersWithoutDomain = (users: string[], domain: string) => users.filter((user) => !user.includes(domain));

const helpers = {
  isAdminOrHRDirector,
  getInitials,
  formatArrayToSelectOptions,
  getPublicationExpirationDate,
  formatObjToSelectOptions,
  formatToPreviewLink,
  getPercentagesFromPayment,
  formatUserSkillsToSelect,
  isHttpUrl,
  getClosedPositionCost,
  formatCompanyWebsite,
  getFileNameFromPath,
  formatCountryToSelectOptions,
  converTextToUrl,
  getStatusColor,
  readOwnNotifications,
  checkIsShowCommentIcon,
  getUnreadedNotificationsCount,
  globalFilter,
  generateEventLink,
  getFullMonthName,
  addHttps,
  generateLinkedInShareLink,
  usersWithoutDomain,
};

export default helpers;
