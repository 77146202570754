import styled from 'styled-components';

const MainPageContent = styled.main`
  padding: 30px;
  min-height: 100%;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.screen.md}px) {
    padding: 15px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    font-size: ${({ theme }) => theme.font.size.lg};
    font-style: normal;
    line-height: 29px;
    word-break: break-word;
  }

  a {
    text-transform: uppercase;
  }
`;

export default MainPageContent;
