/* eslint-disable @typescript-eslint/no-use-before-define */
import axios from 'axios';

import { logOut } from 'utils/helpers';
import token from 'utils/token';
import config from '../config';

export const getTokenAsHeaders = () => `Bearer ${token.access.get()}`;

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    authorization: getTokenAsHeaders(),
  },
  withCredentials: true,
});

export const setAuthorizationToken = () => {
  axios.defaults.headers.authorization = getTokenAsHeaders();
};

axiosInstance.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      authorization: getTokenAsHeaders(),
    },
  }),
  (error) =>
    // Do something with request error
    Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  ({ data }) => data,
  async (err) => {
    if (err?.response?.data === 'invalid') {
      logOut();
      return null;
    }

    if (err?.response?.data === 'expired') {
      // @ts-ignore
      if (!refreshPromise) {
        refreshPromise = refreshToken();
      }
      // @ts-ignore
      const isReseted = await refreshPromise;

      if (!isReseted) {
        throw err;
      }
      // eslint-disable-next-line no-param-reassign
      err.config.headers.Authorization = `Bearer ${token.access.get()}`;

      return axiosInstance(err.config);
    }

    return Promise.reject(err.response);
  },
);
// @ts-ignore
let refreshPromise = null;
const refreshToken = async () => {
  try {
    // @ts-ignore
    const {
      data: { access, refresh },
    } = await axios.post(`${config.apiUrl}/auth/refresh`, {
      token: token.refresh.get(),
    });

    token.access.set(access);
    token.refresh.set(refresh);

    setAuthorizationToken();

    return true;
  } catch (err) {
    logOut();
    return false;
  } finally {
    refreshPromise = null;
  }
};

export default axiosInstance;
