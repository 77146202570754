import { combineReducers } from 'redux';
import * as main from 'store/main';
import * as optionsList from 'store/filterOptions';
import * as notificationsList from 'store/notifications';
import * as discountList from 'store/discount';
import * as vacanciesList from '../pages/Vacancy/store';
import * as companiesList from '../pages/Companies/store';
import * as candidatesList from '../pages/Candidates/store';
import * as recruitersList from '../pages/Recruiters/store';
import * as employeesList from '../pages/Employees/store';
import * as hrDirectorsList from '../pages/HrDirectors/store';
import * as managersList from '../pages/Managers/store';
import * as teamsList from '../pages/Teams/store';

export const fullStore = {
  main: main.default,
  [vacanciesList.sliceName]: vacanciesList.default,
  [companiesList.sliceName]: companiesList.default,
  [candidatesList.sliceName]: candidatesList.default,
  [optionsList.sliceName]: optionsList.default,
  [recruitersList.sliceName]: recruitersList.default,
  [hrDirectorsList.sliceName]: hrDirectorsList.default,
  [managersList.sliceName]: managersList.default,
  [teamsList.sliceName]: teamsList.default,
  [employeesList.sliceName]: employeesList.default,
  [notificationsList.sliceName]: notificationsList.default,
  [discountList.sliceName]: discountList.default,
};

export type StoreType = {
  main: main.StoreType;
  [vacanciesList.sliceName]: vacanciesList.StoreType;
  [companiesList.sliceName]: companiesList.StoreType;
  [candidatesList.sliceName]: candidatesList.StoreType;
  [optionsList.sliceName]: optionsList.StoreType;
  [recruitersList.sliceName]: recruitersList.StoreType;
  [hrDirectorsList.sliceName]: hrDirectorsList.StoreType;
  [managersList.sliceName]: managersList.StoreType;
  [teamsList.sliceName]: teamsList.StoreType;
  [employeesList.sliceName]: employeesList.StoreType;
  [notificationsList.sliceName]: notificationsList.StoreType;
  [discountList.sliceName]: discountList.StoreType,
};

const combinedReducers = combineReducers(fullStore);

export const RESET_STORE_ACTION_TYPE = 'RESET_WHOLE_STORE';

const rootReducer = (
  state: StoreType | undefined,
  action: { type: string },
) => {
  if (action.type === RESET_STORE_ACTION_TYPE) {
    return combinedReducers(undefined, action);
  }
  return combinedReducers(state, action);
};

export const resetStore = () => ({ type: RESET_STORE_ACTION_TYPE });

export default rootReducer;
