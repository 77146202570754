import styled from 'styled-components';

const StyledHeader = styled.header`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.header.backgroundColor};
  color: #363636;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;

  .user-block {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    padding-right: 30px;
  }

  .language {
    display: flex;
    button:first-of-type {
      margin-right: 10px;
    }
  }
`;

export default StyledHeader;
