import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledNotificationRecord from './NotificationRecord/NotificationRecord.styles';

const NoInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledNotificationRecord>
      <div className="wrap">
        <div className="content">{t('no notifications')}</div>
      </div>
    </StyledNotificationRecord>
  );
};

export default NoInfo;
