import React, { memo, useEffect, useState } from 'react';
import { Slide } from '@mui/material';

import UnicornLike from 'ui/components/AnimationModal/UnicornLike';
import { useAppSelector, useAppDispatch } from 'utils/hooks/hooks';
import helpers from 'utils/helpers';
import { getDiscount, closePopup } from 'store/discount/thunks';

import { StyledModal, StyledInfo, LinkedinButton, StyledVariantsContainer }  from './DiscountPopup.style'
import VariantCard from './components/Variant/Variant';
import variants from './config';

const initialPopup = () => ({
  title: 'Congratulations on your successful hire! We look forward to your recommendations.',
  description: 'Enjoy a discount on your next job posting',
});


const DiscountPopup = () => {
  const [popupText, setPopupText] = useState(initialPopup());
  const [isOpenVariants, setIsOpenVariants] = useState(false);
  const [isShowBtn, setIsShowBtn] = useState(true);
  const isOpen = useAppSelector(({ discountPopup }) => discountPopup.isOpen);
  const vacancyTitle = useAppSelector(({ discountPopup }) => discountPopup.vacancy?.title);
  const discount = useAppSelector(({ discountPopup }) => discountPopup.discount);
  const userId = useAppSelector(({ main }) => main.user?.id);
  const subClientCompanyId = useAppSelector(({ main }) => main.company.current?.company?.id);
  const ownerCompanyId = useAppSelector(({ main }) => main.user?.ownerOfCompany?.id);
  const dispatch = useAppDispatch();

  const companyId = subClientCompanyId || ownerCompanyId;

  const toggleChooseText = () => {
    setPopupText({
      ...popupText,
      title: 'Choose a LinkedIn recommendation option.',
    })
    setIsOpenVariants(true);
    setIsShowBtn(false);
  }

  const onChooseVariant = (variant: number) => {
    setIsOpenVariants(false);
    setPopupText({
      title: 'Thank you for your time.',
      description: `An initial discount of ${discount + 1}% has been set for the next offer/invoice`,
    });

    let text = '';
    switch(variant) {
      case 1:
        text = 'Excited to share our excellent experience with https://www.linkedin.com/company/hr-network/ !\nThey were instrumental in helping us find a fantastic new team member quickly and efficiently.\nThank you, www.hrnetwork.club, for your outstanding support!\n\n#hiring #techjobs #ITrecruitment #hrnetwork';
        break;
      case 2:
        text = `We've just successfully filled a vacancy ${vacancyTitle} with https://www.linkedin.com/company/hr-network/ and I highly recommend their services.\nThey demonstrated a high level of professionalism and truly understand the needs of IT companies.\n\nGreat job, www.hrnetwork.club team!\n\n#recommendation #recruiting #IT #hrnetwork`;
        break;
      case 3:
        text = `We've just successfully filled a vacancy ${vacancyTitle} with https://www.linkedin.com/company/hr-network/ and I highly recommend their services.\nThanks to their approach, we found the perfect candidate in no time.\n\nGreat job, www.hrnetwork.club team!\n\n#recommendation #recruiting #IT #hrnetwork`;
        break;
      default: text = '';
    }

    const linkedinShareLink = helpers.generateLinkedInShareLink(text);
    window.open(linkedinShareLink, '_blank');
  }

  useEffect(() => {
    if(!companyId || !isOpen) return;

    dispatch(getDiscount(companyId))
  }, [dispatch, companyId, isOpen]);

  useEffect(() => {
    if (isOpen) return;

    setPopupText(initialPopup());
    setIsShowBtn(true);
    setIsOpenVariants(false);
  }, [isOpen])

  if (!isOpen || !companyId) {
    return null;
  }

  
  const onClose = () => {
    dispatch(closePopup({
      id: companyId,
      userId,
    }));
  };

  return  (
    <StyledModal>
      <Slide direction="left" in={isOpenVariants} mountOnEnter unmountOnExit>
        <StyledVariantsContainer>
          {variants.map((variant) => (
            <VariantCard key={variant.id} companyId={companyId} onChooseVariant={() => onChooseVariant(variant.id)} vacancyTitle={vacancyTitle} {...variant}  />
          ))}
        </StyledVariantsContainer>
      </Slide>
      <UnicornLike width={425} justify='center' onClose={onClose}>
        <StyledInfo className="linkedin">
          <p className="linkedin__title">{popupText.title}</p>
          <p className="linkedin__description">{popupText.description}</p>
          {isShowBtn && (
            <LinkedinButton onClick={toggleChooseText} className="linkedin__btn">
              Enjoy a discount
            </LinkedinButton>
          )}
        </StyledInfo>
      </UnicornLike>

    </StyledModal>
  )
};

export default memo(DiscountPopup);
