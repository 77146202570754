const countriesRu = {
  Remote: 'Удаленно',
  'Ukraine or Remote': 'Украина или Удаленно',
  Russia: 'Россия',
  Ukraine: 'Украина',
  Belarus: 'Беларусь',
  Poland: 'Польша',
  Armenia: 'Армения',
  China: 'Китай',
  Cyprus: 'Кипр',
  Kyrgyzstan: 'Киргизия',
  Latvia: 'Латвия',
  Philippines: 'Филиппины',
  Taiwan: 'Тайвань',
};

export default countriesRu;
