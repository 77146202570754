import { createGlobalStyle } from 'styled-components';

type SidebarPropTypes = {
  isOpen?: boolean;
};

const RootLeftPadding = createGlobalStyle<SidebarPropTypes>`
#root {
  padding-left: ${({ theme, isOpen }) =>
    isOpen ? theme.sidebar.width.open : theme.sidebar.width.closed};
}
`;

export default RootLeftPadding;
