import styled from 'styled-components';

const StyledBell = styled.div<{ isCounterVisible: boolean }>`
  margin-top: 5px;
  position: relative;

  svg {
    cursor: pointer;
  }

  .notifications-counter {
    display: ${({isCounterVisible}) => isCounterVisible ? 'flex': 'none'} ;
    justify-content: center;
    align-items: center;


    width: 25px;
    height: 14px;
    line-height: 10px;
    font-size: ${({ theme }) => theme.font.size.sm};
    padding: 3px 5px 3px 7px;
    letter-spacing: 0.04em;
    border-radius: 10px;

    background: #60c837;
    color: white;
    z-index: 2;

    position: absolute;
    top: -25%;
    left: 50%;
  }
`;

export default StyledBell;
