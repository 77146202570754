const citiesEn = {
  Архангельск: 'Arkhangelsk',
  Астрахань: 'Astrakhan',
  Балашиха: 'Balashikha',
  Барнаул: 'Barnaul',
  Белгород: 'Belgorod',
  Брянск: 'Bryansk',
  'Великий Новгород': 'Veliky Novgorod',
  Владивосток: 'Vladivostok',
  Владикавказ: 'Vladikavkaz',
  Владимир: 'Vladimir',
  Волгоград: 'Volgograd',
  Вологда: 'Vologda',
  Воронеж: 'Voronezh',
  Грозный: 'Grozny',
  Домодедово: 'Domodedovo',
  Екатеринбург: 'Yekaterinburg',
  Жуковский: 'Zhukovsky',
  Иваново: 'Ivanovo',
  Ижевск: 'Izhevsk',
  Иркутск: 'Irkutsk',
  'Йошкар-Ола': 'Yoshkar-Ola',
  Казань: 'Kazan',
  Калининград: 'Kaliningrad',
  Калуга: 'Kaluga',
  Кемерово: 'Kemerovo',
  Киров: 'Kirov',
  Коломна: 'Kolomna',
  Королёв: 'Korolyov',
  Красногорск: 'Krasnogorsk',
  Краснодар: 'Krasnodar',
  Красноярск: 'Krasnoyarsk',
  Курск: 'Kursk',
  Липецк: 'Lipetsk',
  Люберцы: 'Lyubertsy',
  Магнитогорск: 'Magnitogorsk',
  Махачкала: 'Makhachkala',
  Москва: 'Moscow',
  'Московская область': 'Moscow region',
  Мурманск: 'Murmansk',
  Мытищи: 'Mytishchi',
  'Набережные Челны': 'Naberezhnye Chelny',
  'Нижний Новгород': 'Nizhny Novgorod',
  Новокузнецк: 'Novokuznetsk',
  Новороссийск: 'Novorossiysk',
  Новосибирск: 'Novosibirsk',
  Ногинск: 'Noginsk',
  Одинцово: 'Odintsovo',
  Омск: 'Omsk',
  Оренбург: 'Orenburg',
  Орёл: 'Oryol',
  Пенза: 'Penza',
  Пермь: 'Perm',
  Петрозаводск: 'Petrozavodsk',
  Подольск: 'Podolsk',
  Псков: 'Pskov',
  Пятигорск: 'Pyatigorsk',
  Раменское: 'Ramenskoye',
  'Ростов-на-Дону': 'Rostov-on-Don',
  Рязань: 'Ryazan',
  Самара: 'Samara',
  'Санкт-Петербург': 'Saint Petersburg',
  Саратов: 'Saratov',
  Севастополь: 'Sevastopol',
  'Сергиев Посад': 'Sergiyev Posad',
  Серпухов: 'Serpukhov',
  Симферополь: 'Simferopol',
  Смоленск: 'Smolensk',
  Сочи: 'Sochi',
  Ставрополь: 'Stavropol',
  Сургут: 'Surgut',
  Таганрог: 'Taganrog',
  Тамбов: 'Tambov',
  Тверь: 'Tver',
  Тольятти: 'Tolyatti',
  Томск: 'Tomsk',
  Тула: 'Tula',
  Тюмень: 'Tyumen',
  'Улан-Удэ': 'Ulan-Ude',
  Ульяновск: 'Ulyanovsk',
  Уфа: 'Ufa',
  Хабаровск: 'Khabarovsk',
  Химки: 'Khimki',
  Чебоксары: 'Cheboksary',
  Чита: 'Chita',
  Энгельс: 'Engels',
  Ярославль: 'Yaroslavl',
  Винница: 'Vinnytsia',
  Днепр: 'Dnipro',
  Запорожье: 'Zaporizhzhia',
  Киев: 'Kyiv',
  Львов: 'Lviv',
  Одесса: 'Odesa',
  Полтава: 'Poltava',
  Харьков: 'Kharkiv',
  Хмельницкий: 'Khmelnytskyi',
  Черкассы: 'Cherkasy',
  Гродно: 'Grodno',
  Минск: 'Minsk',
  Быдгощ: 'Bydgoszcz',
  Варшава: 'Warsaw',
  Ворцлав: 'Wrocław',
  Гданьск: 'Gdansk',
  Краков: 'Krakow',
  Лодзь: 'Łódź',
  Люблин: 'Lublin',
  Познань: 'Poznan',
  Щецин: 'Szczecin',
  Ереван: 'Yerevan',
  Beijing: 'Beijing',
  Chengdu: 'Chengdu',
  Chongqing: 'Chongqing',
  Dongguan: 'Dongguan',
  Guangzhou: 'Guangzhou',
  Shanghai: 'Shanghai',
  Shenyang: 'Shenyang',
  Shenzhen: 'Shenzhen',
  Tianjin: 'Tianjin',
  Wuhan: 'Wuhan',
  'Kato Polemidia': 'Kato Polemidia',
  Lacatamia: 'Lacatamia',
  Larnaca: 'Larnaca',
  Limassol: 'Limassol',
  Nicosia: 'Nicosia',
  Paphos: 'Paphos',
  Strovolos: 'Strovolos',
  Bishkek: 'Bishkek',
  Osh: 'Osh',
  Daugavpils: 'Daugavpils',
  Jekabpils: 'Jekabpils',
  Jelgava: 'Jelgava',
  Jurmala: 'Jurmala',
  Liepaja: 'Liepaja',
  Ogre: 'Ogre',
  Rezekne: 'Rezekne',
  Riga: 'Riga',
  Sigulda: 'Sigulda',
  Valmiera: 'Valmiera',
  Ventspils: 'Ventspils',
  Kalookan: 'Kalookan',
  'Las Pinas': 'Las Pinas',
  Makati: 'Makati',
  Manila: 'Manila',
  Marikina: 'Marikina',
  Mountainloop: 'Mountainloop',
  Parañaque: 'Parañaque',
  Pasig: 'Pasig',
  'Queson city': 'Queson city',
  Taguig: 'Taguig',
  Valenzuela: 'Valenzuela',
  Chiayi: 'Chiayi',
  Hsinchu: 'Hsinchu',
  Jilong: 'Jilong',
  Kaohsiung: 'Kaohsiung',
  Tainan: 'Tainan',
  Taipei: 'Taipei',
};

export default citiesEn;
