import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const StyledModal = styled.div`
  position: fixed;
  z-index: 30;
  bottom: 28px;
  right: 60px;

  display: flex;
  align-items: end;
  gap: 20px;
`;

export const StyledInfo = styled.div<{ disabled?: boolean }>`
  && {
    padding: 26px 50px
  }

  .linkedin {
    &__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 30px;
      text-transform: uppercase;
    }

    &__description {
      font-size: 17px;
      font-weight: 600;
      line-height: 28px;
    }

    &__btn {
      margin-top: 30px;
      width: 100%;
    }
  }
`

export const LinkedinButton = styled(Button)<{ isVariant?: boolean }>`
  && {
    padding: ${props => props.isVariant ? '12px 30px' : '19.5px 45px'};
    background-color: #A362E6;
    color: white;

    &:disabled {
      background-color: #D8D8D8;
      color: black;
    }
  }
`
export const StyledVariantsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 587px;
`
