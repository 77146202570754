/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// import { Button } from '@material-ui/core';

import logoImage from 'ui/images/newLogo.svg';

import { routePaths } from 'utils/constants';
import { useAppDispatch, useAppSelector } from 'utils/hooks';

// import { changeLanguage } from 'store/main';
import StyledHeader from './Header.style';
import UserMenu from './components/UserMenu';
import Bell from './components/Bell';
import CompanyChooser from './components/CompanyChooser';

const Header: React.FC = () => {
  // the client asked to temporarily hide this functionality
  // const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const { lang } = useAppSelector(({ main }) => main);
  const isSubClient = useAppSelector(({ main }) => main?.user?.isSubclient);

  useEffect(() => {
    (() => i18n.changeLanguage(lang))();
  }, [i18n, lang]);

  // the client asked to temporarily hide this functionality
  // const changeLang = useCallback(
  //   (e) => {
  //     dispatch(changeLanguage(e.target.innerHTML));
  //   },
  //   [dispatch],
  // );

  return (
    <StyledHeader>
      <div>
        <Link to={routePaths.vacancy.list}>
          <img src={logoImage} alt="logo" />
        </Link>
      </div>
      <div className="user-block">
        <Bell />
        {isSubClient && <CompanyChooser />}
        <UserMenu />
        {/* <div className="language">
          <Button disabled={lang === 'ru'} onClick={changeLang}>
            ru
          </Button>
          <Button disabled={lang === 'en'} onClick={changeLang}>
            en
          </Button>
        </div> */}
      </div>
    </StyledHeader>
  );
};

export default memo(Header);
