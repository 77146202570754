import React, { memo, useEffect,  useState } from 'react';
import { ToastContainer } from 'react-toastify';

import Router from 'Router';
import Loader from 'ui/components/Loader';
import Header from 'ui/containers/Header';
import Sidebar from 'ui/containers/Sidebar';
import MainPageContent from 'ui/components/MainPageContent';

import { useAppDispatch, useAppSelector } from 'utils/hooks';
import useSocket from 'utils/hooks/useSocket';

import authorize from 'store/main/thunks';
import './i18n';
import getNotificationsList from 'store/notifications/thunks';
import DiscountPopup from 'ui/containers/DiscountPopup/DiscountPopup';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { user } = useAppSelector(({ main }) => main);

  useSocket();

  useEffect(() => {
    (async () => {
      await dispatch(authorize());

      setIsAuthorized(true);
    })();
  }, [dispatch]);

  useEffect(() => {
    if (isAuthorized && user) {
      (async () => {
        await dispatch(getNotificationsList(user.id));
      })();
    }
  }, [dispatch, isAuthorized, user, user?.id]);

  if (!isAuthorized) {
    return (
      <MainPageContent>
        <h1>Loading</h1>
      </MainPageContent>
    );
  }

  return (
    <>
      <ToastContainer
        autoClose={3000}
        draggable
        newestOnTop
        closeButton={false}
      />

      <Loader />
      <Header />
      <Sidebar />
      <DiscountPopup />
      <Router />
    </>
  );
};

export default memo(App);
