import React from 'react';

const UserIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.9999 9.99996C12.3011 9.99996 14.1666 8.13448 14.1666 5.83329C14.1666 3.53211 12.3011 1.66663 9.9999 1.66663C7.69871 1.66663 5.83323 3.53211 5.83323 5.83329C5.83323 8.13448 7.69871 9.99996 9.9999 9.99996Z" />
    <path d="M3.38121 14.5192C2.45241 15.7313 3.02195 17.4264 4.42559 18.0278C6.13644 18.7608 8.02076 19.1666 9.99987 19.1666C11.979 19.1666 13.8633 18.7608 15.5741 18.0278C16.9778 17.4265 17.5473 15.7313 16.6185 14.5192C15.8516 13.5183 14.8627 12.6965 13.7255 12.1271L11.6665 15.8333L9.99987 13.3333L12.8012 11.7325C11.9257 11.4201 10.9827 11.25 9.99986 11.25C9.01706 11.25 8.07399 11.4201 7.19851 11.7325L9.99987 13.3333L8.33321 15.8333L6.27422 12.1271C5.137 12.6965 4.14815 13.5183 3.38121 14.5192Z" />
  </svg>
);

export default UserIcon;
