import React, { memo } from 'react';
import styled from 'styled-components';

import Portal from '@material-ui/core/Portal';
import { CircularProgress } from '@material-ui/core';

import { useAppSelector } from 'utils/hooks';

type PropsType = {
  show?: boolean;
};

const loaderSize = 250;

export const StyledLoader = styled(CircularProgress)`
  width: ${loaderSize}px;
  height: ${loaderSize}px;
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
`;

const Loader: React.FC<PropsType> = (props) => {
  const showLoader = useAppSelector(({ main }) => main.showLoader);

  if (!showLoader && !props.show) {
    return null;
  }

  return (
    <Portal>
      <StyledLoader color="secondary" />
    </Portal>
  );
};

export default memo(Loader);
