import axios from 'api/axios';
import token from 'utils/token';

const path = '/auth';

export const check = () => {
  if (!token.access.get()) {
    return null;
  }

  return axios.get(`${path}/me`);
};

const authApi = {
  check,
};

export default authApi;
