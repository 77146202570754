export const envTypes = {
  dev: 'development',
  stage: 'staging',
  prod: 'production',
  test: 'test',
};

const envType =
  process.env.REACT_APP_ENV || process.env.NODE_ENV || envTypes.dev;

export const isDev = envType === envTypes.dev || envType === envTypes.stage;

const config = {
  apiUrl: 'http://localhost:3000/api/v2',
  baseUrl: 'http://localhost:3000',
  envType,
  appId: 'hr_network_crm',
  domain: '.test.com',
};

switch (envType) {
  case envTypes.prod:
    config.apiUrl = 'https://hrnetwork.club/api/v2';
    config.baseUrl = 'https://hrnetwork.club';
    config.appId = 'hr_network_crm';
    config.domain = '.hrnetwork.club';
    break;

  case envTypes.stage:
    config.apiUrl = 'https://stage.hrnetwork.club/api/v2';
    config.baseUrl = 'https://stage.hrnetwork.club';
    config.appId = 'hr_network_crm';
    config.domain = '.hrnetwork.club';
    break;

  default:
}

export default config;
