import React from 'react';

import { Route, RouteComponentProps } from 'react-router-dom';

type Props = {
  path: string | string[];
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  exact: boolean;
};

const CustomRoute = (props: Props): JSX.Element => <Route path={props.path} component={props.component} exact={props.exact} />;

export default CustomRoute;
