import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnimationSkip, EmployeesType, UserTypeCommonFields } from 'utils/types';

export const ThemeType = { main: 'main' };

const getInitialStore = () => ({
  user: null as unknown as UserTypeCommonFields,
  company: {
    current: null as EmployeesType | null,
    list: [] as EmployeesType[],
  },
  showLoader: false,
  isSidebarOpen: false,
  theme: ThemeType.main,
  lang: 'en',
  animations: [] as AnimationSkip[],
});

const initialStore = getInitialStore();
export type StoreType = typeof initialStore;

// need to fix user type;
export const updateUserAction: CaseReducer<
  StoreType,
  PayloadAction<any | null>
> = (store, action) => ({
  ...store,
  user: action.payload,
  animations: action.payload.animations,
});

export const toggleLoaderAction: CaseReducer<
  StoreType,
  PayloadAction<boolean | string>
> = (store, { payload }) => ({
  ...store,
  showLoader: typeof payload === 'boolean' ? payload : !store.showLoader,
});

const toggleSidebarAction: CaseReducer<
  StoreType,
  PayloadAction<boolean | string>
> = (store, { payload }) => ({
  ...store,
  isSidebarOpen: typeof payload === 'boolean' ? payload : !store.isSidebarOpen,
});

const changeLanguageAction: CaseReducer<StoreType, PayloadAction<string>> = (
  store,
  { payload },
) => ({
  ...store,
  lang: payload,
});

const fillCompanyAction: CaseReducer<
  StoreType,
  PayloadAction<EmployeesType[]>
> = (store, { payload }) => {
  store.company.list = payload;
  // eslint-disable-next-line
  store.company.current = payload[0];
};

const changeCurrentCompanyAction: CaseReducer<
  StoreType,
  PayloadAction<EmployeesType>
> = (store, { payload }) => {
  store.company.current = payload;
};

const animationSkipUpdateAction: CaseReducer<
  StoreType,
  PayloadAction<AnimationSkip>
> = (store, { payload }) => {
  const foundSkip = store.animations.find(skip => skip.animationName === payload.animationName);
  const newSkips = [...store.animations];

  if (!foundSkip) {
    newSkips.push(payload)
  }

  store.animations = newSkips.map((skip) => ({
    ...skip,
    times: skip.animationName === payload.animationName ? payload.times : skip.times,
  }));
};

const mainSlice = createSlice({
  name: 'main',
  initialState: getInitialStore(),
  reducers: {
    updateUser: updateUserAction,
    toggleLoader: toggleLoaderAction,
    toggleSidebar: toggleSidebarAction,
    changeLanguage: changeLanguageAction,
    fillCompany: fillCompanyAction,
    changeCurrentCompany: changeCurrentCompanyAction,
    animationSkipUpdate: animationSkipUpdateAction,
  },
});

export const {
  toggleSidebar,
  toggleLoader,
  updateUser,
  changeCurrentCompany,
  changeLanguage,
  fillCompany,
  animationSkipUpdate,
} = mainSlice.actions;

export default mainSlice.reducer;
