import React, { FC, useEffect, useMemo } from "react";
import Lottie from 'react-lottie';
import { IconButton } from "@material-ui/core";

import { ReactComponent as CloseSVG } from 'ui/images/unicorn-close.svg'
import animationData from 'ui/animation/unicorn-like/like-anim.json';

import sound from '../../../../static/unicorn-like.mp3';
import StyledPaper from "./index.style";

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    className: 'unicorn-like__animation-svg',
  },
};

type Props = {
  onClose: () => void;
  children: React.ReactNode;
  width?: number;
  justify?: string;
}

const UnicornLike: FC<Props> = ( { onClose, children, width, justify } ) => {
  const audio = useMemo(() => new Audio(sound), []);

  useEffect(() => {
    audio.play()
  }, [audio])

  return (
    <StyledPaper width={width} justify={justify} className="unicorn-like">
      <div className="unicorn-like__animation-container">
        <Lottie
          options={animationOptions}
          height={180}
          width={235}
          isClickToPauseDisabled
        />
      </div>

      <IconButton onClick={onClose} className="unicorn-like__close-btn">
        <CloseSVG />
      </IconButton>
      {children}
    </StyledPaper>
  )
}

export default UnicornLike;
