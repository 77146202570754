/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';

const getInitialStore = () => ({
  isNotificationsModalOpen: false,
  notificationsList: [],
  totalCount: 0,
});

const initialStore = getInitialStore();
export type StoreType = typeof initialStore;
export const sliceName = 'notificationsList';

const notificationListSlice = createSlice({
  name: sliceName,
  initialState: getInitialStore(),
  reducers: {
    setNotificationList: (store, { payload }) => ({
      ...store,
      notificationsList: payload,
    }),

    clearNotificationList: (store, { payload }) => {
      store.notificationsList = store.notificationsList.filter(
        (notification) => !payload.notificationsList.includes(notification.id),
      );
    },

    addOneEventToNotificationList: (store, { payload }) => ({
        ...store,
        notificationsList: [payload, ...store.notificationsList],
      }),

    markNotificationsAsRead: (store, { payload }) => ({
      ...store,
      notificationsList: payload,
    }),

    markAsReadByEntityId: (
      store,
      {
        payload,
      }: {
        payload: {
          types: string[];
          entityIdKey: string;
          id: number;
        };
      },
    ) => {
      const storeNotificationsCopy = [...store.notificationsList];
      const updatedNotificationsList = storeNotificationsCopy.map((i) =>
        payload.types.includes(i.iconType) &&
        +i[payload.entityIdKey] === +payload.id
          ? { ...i, isRead: true }
          : i,
      );

      return {
        ...store,
        notificationsList: updatedNotificationsList,
      };
    },

    toggleNotificationsModal: (store, { payload }) => ({
      ...store,
      isNotificationsModalOpen:
        typeof payload === 'boolean'
          ? payload
          : !store.isNotificationsModalOpen,
    }),
  },
});

export const {
  setVacanciesNotificationList,
  setCandidatesNotificationList,
  toggleNotificationsModal,
  addOneEventToVacanciesNotificationList,
  addOneEventToCandidatesNotificationList,
  markAsRead,
  markAsReadByEntityId,
  setNotificationList,
  addOneEventToNotificationList,
  markNotificationsAsRead,
  clearNotificationList,
} = notificationListSlice.actions;

export default notificationListSlice.reducer;
