import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDebouncedFunction = (
  func: any,
  delay: number,
  cleanUp = false,
) => {
  const timeOutRef = useRef();

  const clearTimer = () => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
      timeOutRef.current = undefined;
    }
  };

  // @ts-ignore
  useEffect(() => (cleanUp ? clearTimer : null), [cleanUp]);

  return (...args: any[]) => {
    clearTimer();

    //  @ts-ignore
    timeOutRef.current = setTimeout(() => func(...args), delay);
  };
};

//  TODO - maybe its solve issue with errors translation
export const useTranslateFormErrors = <E, T>(
  errors: E,
  touched: T,
  setFieldTouched: any,
): void => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.on('languageChanged', () => {
      Object.keys(errors).forEach((fieldName) => {
        if (Object.keys(touched).includes(fieldName)) {
          setFieldTouched(fieldName);
        }
      });
    });
    return () => {
      i18n.off('languageChanged', () => {});
    };
  }, [errors, i18n, setFieldTouched, touched]);
};

export const useImageWithPreview = () => {
  const [selectedFile, setSelectedFile] = useState<string | undefined | File>();
  const [preview, setPreview] = useState<string | undefined | Blob>();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile as unknown as Blob);
    setPreview(objectUrl);

    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files.length) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const cleanFile = () => {
    setSelectedFile(undefined);
  };

  return {
    preview,
    selectedFile,
    onSelectedFile,
    cleanFile,
  };
};


type ClickOutsideHandler = (event: MouseEvent) => void;

export const useClickOutside = (ref: React.RefObject<HTMLElement>, handler: ClickOutsideHandler): void => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref, handler]);
}
