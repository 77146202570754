/* eslint-disable no-console */
import io from 'socket.io-client';
import { useEffect } from 'react';

import token from 'utils/token';
import config from 'config';
import {
  addOneEventToNotificationList,
  clearNotificationList,
} from 'store/notifications/reducer';
import { readOwnNotifications } from 'utils/helpers';
import { addRecordToHistoryList } from 'pages/Candidates/store/reducer';
import {
  addOneCompanyToStart,
  setOrderedIdsToStart,
} from 'pages/Companies/store/reducer';
import getNotificationsList from 'store/notifications/thunks';
import { togglePopup, setVacancy } from 'store/discount/reducer';
import { checkDiscount } from 'store/discount/thunks';
import { useAppDispatch, useAppSelector } from './hooks';

const socket = io(config.baseUrl, {
  auth: {
    token: token.access.get(),
  },
  withCredentials: true,
  transports: ['websocket'],
});

const useSocket = () => {
  const { user } = useAppSelector(({ main }) => main);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!socket.connected) {
      // @ts-ignore
      socket.on('connect', () => {
        dispatch(checkDiscount());
        console.log('user connected');
      });
    }

    if (user) {
      socket.emit('myInfo', user.id);
    }

    socket.on('disconnect', (reason) => {
      console.log('disconnect', reason);
    });

    socket.on('vacancyCreated', (res) => {
      dispatch(
        addOneEventToNotificationList(readOwnNotifications(res.data, user.id)),
      );
    });

    socket.on('vacancyStatusChanged', (res) => {
      dispatch(
        addOneEventToNotificationList(readOwnNotifications(res.data, user.id)),
      );
    });

    socket.on('newCandidate', (res) => {
      dispatch(
        addOneEventToNotificationList(readOwnNotifications(res.data, user.id)),
      );
    });

    socket.on('vacancyShared', (res) => {
      dispatch(
        addOneEventToNotificationList(readOwnNotifications(res.data, user.id)),
      );
    });

    socket.on('candidateNewComment', (res) => {
      dispatch(
        addOneEventToNotificationList(readOwnNotifications(res.data, user.id)),
      );

      dispatch(
        addRecordToHistoryList({
          ...res.data,
          vacancy_id: +res.data.vacancyId,
          candidate_id: +res.data.candidateId,
        }),
      );
    });

    socket.on('candidateStatusUpdated', (res) => {
      dispatch(
        addOneEventToNotificationList(readOwnNotifications(res.data, user.id)),
      );
    });

    socket.on('newClient', (res) => {
      dispatch(addOneEventToNotificationList(res.data));
      dispatch(addOneCompanyToStart(res.data.client));
      dispatch(setOrderedIdsToStart(res.data.clientId));
    });

    socket.on('clearIrrelevantNotifications', async (res) => {
      await dispatch(getNotificationsList(user.id));
      dispatch(clearNotificationList(res.data));
    });

    socket.on('linkedinDiscount', (res) => {
      dispatch(setVacancy(res.data));
      dispatch(togglePopup(true));
    })

    socket.on('connect_error', (err) => console.log(err));

    return () => {
      socket.off('myInfo');
      socket.off('disconnect');
      socket.off('vacancyCreated');
      socket.off('vacancyStatusChanged');
      socket.off('newCandidate');
      socket.off('vacancyShared');
      socket.off('candidateNewComment');
      socket.off('candidateStatusUpdated');
      socket.off('newClient');
      socket.off('clearNotifications');
      socket.off('linkedinDiscount');

      socket.off('connect_error');
    };
  }, [dispatch, user]);
};

export default useSocket;
