import React, { memo } from 'react';

import { ThemeProvider } from '@material-ui/styles';

import themes from 'ui/styles/MaterialTheme/themes/materialThemes';

import { useAppSelector } from 'utils/hooks';
import { ChildrenType } from 'utils/types';

declare global {
  interface Window {
    __MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__: boolean;
  }
}

// eslint-disable-next-line no-underscore-dangle
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

type PropType = {
  children: ChildrenType;
};

const MaterialProvider: React.FC<PropType> = ({ children }) => {
  const theme = useAppSelector(({ main }) => main.theme);

  return <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>;
};

export default memo(MaterialProvider);
