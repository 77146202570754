import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledNotificationRecord = styled.section`
  border-top: ${({ theme }) => theme.colors.mainBorder};

  .content {
    position: relative;
    padding: 14px 14px 11px 12px;
    display: flex;
    justify-content: flex-start;
    font-weight: ${({ theme }) => theme.font.weight.md};
    align-items: center;
  }

  .record {
    &-type-icon {
      align-self: baseline;
      padding-top: 2px;
      margin-top: 4px;

      svg {
        padding-right: 8px;
        margin: 0;
      }
    }

    &-company-name {
      position: absolute;
      top: -7px;
      z-index: 1;
      background: white;
      left: 0;
      padding: 0 10px;
      font-weight: ${({ theme }) => theme.font.weight.lg};
      height: 20px;
    }

    &-message {
      width: 100%;

      &-text {
      }

      &-time {
        font-size: ${({ theme }) => theme.font.size.sm};
        color: #979797;
      }
    }

    &-is-read-icon {
      width: 12px;
      height: 12px;

      &-circle {
        width: 100%;
        height: 100%;
        background: #c4c4c4;
        border-radius: 50%;
        background: #60c837;
      }
    }
  }
`;

export const StyledNotificationsLink = styled(Link)`
  text-decoration: underline;
`;

export default StyledNotificationRecord;
