import styled from 'styled-components';

type SidebarProps = {
  isOpen?: boolean;
  props?: {
    isOpen: boolean | string;
  };
};

const StyledSidebar = styled.aside<SidebarProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  top: 66px;
  bottom: 0;
  left: 0;
  transition: ${({ theme }) => theme.transition};
  width: ${({ isOpen, theme }) => (isOpen ? theme.sidebar.width.open : theme.sidebar.width.closed)};
`;

export default StyledSidebar;
